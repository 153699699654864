import React from "react";
import styled from "styled-components";

const Li = styled.li`
  margin-right: 15px;

  a {
    font-size: 13px;
    color: #7d7d7d;

    i {
      margin-right: 3px;
      font-size: 12px;
    }

    &:hover {
      color: #40a9ff;
    }
  }
`;

export default function PanelAction(props: any) {
    return (
        <Li>
            <a onClick={() => props.onClick ? props.onClick() : null}>
                {props.icon}
                <span>{props.title}</span>
            </a>
        </Li>
    )
}
