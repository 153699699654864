import {AUTH_CHANGE} from "../types/auth.type"

export const actionAuthChange = (user: any) =>  async (dispatch: any) => {
    dispatch({
        type: AUTH_CHANGE,
        payload: {
            user
        }
    });
}
