import {Switch, Route,} from "react-router-dom";
import HomePage from "../pages/Home";
import AmenityPage from "../pages/Properties/Amenity";
import BenefitPage from "../pages/Properties/Benefit";
import ListHotelPage from "../pages/Hotel/List";
import AddHotelPage from "../pages/Hotel/Add";
import CountryPage from "../pages/Properties/Country";
import BedPage from "../pages/Properties/Bed";
import CityPage from "../pages/Properties/City";
import PlacePage from "../pages/Properties/Place";
import FeaturePage from "../pages/Properties/Feature";
import AddRoomPage from "../pages/Hotel/Room/Add";
import AmenityCategoryPage from "../pages/Properties/AmenityCategory";
import ListRoomPage from "../pages/Hotel/Room/List";
import ListPricePage from "../pages/Hotel/Price/List";
import ListPriceGroupPage from "../pages/Hotel/PriceGroup/List";
import PriceGroupPage from "../pages/Properties/PriceGroup";
import DetailPricePage from "../pages/Hotel/Price/Detail";

export default function AppRoute(props: any) {
    return <Switch>
        <Route path="/home">
            <HomePage/>
        </Route>
        <Route path="/properties/amenity">
            <AmenityPage/>
        </Route>
        <Route path="/properties/amenity-category">
            <AmenityCategoryPage/>
        </Route>
        <Route path="/properties/feature">
            <FeaturePage/>
        </Route>
        <Route path="/properties/benefit">
            <BenefitPage/>
        </Route>
        <Route path="/properties/country">
            <CountryPage/>
        </Route>
        <Route path="/properties/bed">
            <BedPage/>
        </Route>
        <Route path="/properties/city">
            <CityPage/>
        </Route>
        <Route path="/properties/place">
            <PlacePage/>
        </Route>
        <Route path="/properties/price-group">
            <PriceGroupPage/>
        </Route>

        <Route path="/hotel/list">
            <ListHotelPage/>
        </Route>
        <Route path="/hotel/add">
            <AddHotelPage/>
        </Route>
        <Route path="/hotel/update/:hotelId">
            <AddHotelPage/>
        </Route>

        <Route path="/room/list/:hotelId">
            <ListRoomPage/>
        </Route>
        <Route path="/room/add/:hotelId">
            <AddRoomPage/>
        </Route>
        <Route path="/room/update/:roomId">
            <AddRoomPage/>
        </Route>

        <Route path={'/price/:hotelId'}>
            <ListPricePage/>
        </Route>

        <Route path={'/price-detail/:hotelId/:roomId'}>
            <DetailPricePage/>
        </Route>

        <Route path={'/price-group/:hotelId'}>
            <ListPriceGroupPage/>
        </Route>
    </Switch>
}
