import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Space,
    Table,
    Tabs, Tag, Tree
} from "antd";
import Content from "../../../components/Containers/Content";
import {Link, useHistory, useParams} from "react-router-dom";
import PriceGroupService from "../../../services/priceGroup.service";
import PanelActions from "../../../components/Containers/PanelActions";
import PanelAction from "../../../components/Elements/PanelAction";
import DateRangePicker from "../../../components/DateRangePicker";
import PropertyService from "../../../services/property.service";
import {applyTemplate, convertPriceGroupToObject, convertPropertyToIdName} from "../../../services/app.service";
import RoomService from "../../../services/room.service";
import moment from "moment";
import "./style.scss";
import PriceService from "../../../services/price.service";
import Status from "../../../components/Status";

export default function ListPriceGroupPage(props: any) {
    const {hotelId}: any = useParams();
    const history = useHistory();
    const [priceGroups, setPriceGroups]: any = useState([]);
    const [rooms, setRooms]: any = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selected, setSelected]: any = useState();
    const [groupTypeId, setGroupTypeId] = useState();
    const [promotionMessage, setPromotionMessage] = useState('');
    const [actionType, setActionType] = useState('ADD');
    const [priceGroupTypes, setPriceGroupTypes]: any = useState([]);
    const [prices, setPrices]: any = useState([]);
    const [form]: any = Form.useForm();

    useEffect(() => {
        getInit();
        onListPriceGroup();
    }, []);

    useEffect(() => {
        autoUpdateName();
    }, [form])

    const onListPriceGroup = () => {
        PriceGroupService.list(hotelId).then(value => {
            if (value) {
                setPriceGroups(value);
            }
        });
    }

    const getInit = async () => {
        let result: any = await PropertyService.listByGroup(['price-group']).catch(reason => []);
        const values = convertPropertyToIdName(result['price-group'], '', {
            fields: 'fields',
            template: 'template',
            roomName: 'roomName'
        });
        setPriceGroupTypes(values);
        setGroupTypeId(values[0]._id);
        //
        PriceService.list(hotelId).then(value => {
            if (value) {
                setPrices(value);
            }
        });
        const value = await RoomService.list(hotelId);
        if (value) {
            setRooms(value);
        }
    }

    const columns = [
        {
            title: 'Tên nhóm giá',
            dataIndex: 'name',
            key: 'name',
            render: (value: any) => <b className={'one-line'}>{value}</b>
        },
        {
            title: 'Từ ngày',
            dataIndex: 'fromDate',
            key: 'fromDate',
            render: (value: any) => value ? moment(value).format('DD/MM/YYYY') : '---'
        },
        {
            title: 'Đến ngày',
            dataIndex: 'toDate',
            key: 'toDate',
            render: (value: any) => value ? moment(value).format('DD/MM/YYYY') : '---'
        },
        {
            title: 'Giảm giá',
            dataIndex: ['discount', 'value'],
            key: 'discount',
            render: (value: any, row: any) => {
                return `${row.discount.value}${row.discount.unit === 'percent' ? '%' : 'đ'}`
            }
        },
        {
            title: 'Phòng áp dụng',
            dataIndex: ['value'],
            key: 'rooms',
            render: (value: any, row: any, index: number) => {
                return row.rooms.length;
            }
        },
        {
            title: 'Điều kiện',
            dataIndex: 'value',
            key: 'valueC',
            render: (value: any, row: any) => {
                const keys = Object.keys(value || {});
                return keys.map((value1, index) => {
                    return <Tag key={index.toString()}>{value1}:{value[value1]}</Tag>
                })
            }
        },
        {
            title: 'Chức năng',
            dataIndex: 'actions',
            key: 'actions',
            render: (value: string, row: any, index: number) => {
                return row.typeId ? (
                    <Space size="middle">
                        <Button onClick={event => openModalUpdate(row)} type="primary" size="small"
                                ghost>Sửa</Button>
                    </Space>
                ) : null
            }
        },
    ];

    const handleOk = () => {
        if (actionType === "ADD") {
            handleAdd(form.getFieldsValue()).then();
        }
        // if (actionType === "UPDATE") {
        //     handleUpdate(form.getFieldsValue()).then();
        // }
        setIsModalVisible(false);
    };

    const handleCancel = async () => {
        setIsModalVisible(false);
    };

    const handleAdd = async (item: any) => {
        const result = await PriceGroupService.add({
            hotelId, ...item,
            daysOfWeek: item.daysOfWeek ? item.daysOfWeek.filter((x: any) => x >= 1).sort() : [],
        });
        if (result) {
            onListPriceGroup();
        }
    }
    const handleUpdate = async (values: any) => {
        const priceId = selected._id;
        await PriceGroupService.update(priceId, values);
        onListPriceGroup();
    }

    const onOpenModal = () => {
        setIsModalVisible(true);
        setActionType('ADD')
    }

    const openModalUpdate = (group: any) => {
        setIsModalVisible(true);
        setActionType('UPDATE');
        setSelected(group);
        setGroupTypeId(group.typeId);
        form.setFieldsValue({
            ...group,
            fromDate: moment(group.fromDate),
            toDate: moment(group.toDate),
            dateRange: [moment(group.fromDate), moment(group.toDate)]
        });
    }

    const getFields = () => {
        const f = priceGroupTypes.find((x: any) => x._id === form.getFieldsValue().typeId);
        return f ? f.fields : [];
    }

    const onPriceDetail = (roomId: string) => {
        history.push(`/price-detail/${hotelId}/${roomId}`)
    }


    const autoUpdateName = () => {
        const values = form.getFieldsValue();
        const obj = convertPriceGroupToObject(values);
        const group = priceGroupTypes.find((x: any) => x._id === values.typeId);
        if (group) {
            const name = applyTemplate(group.name, obj);
            form.setFieldsValue({name});
            setPromotionMessage(applyTemplate(group.template.vi, obj))
        }
    }

    const renderRooms = (group: any) => {
        const cols: Array<any> = [
            {
                title: 'Loại phòng',
                dataIndex: ['name', 'vi'],
                key: 'name',
                render: (value: string, row: any, index: number) => {
                    return <span className={`room-name ${value.indexOf('<b>') < 0 ? 'not-name' : ''}`}
                                 dangerouslySetInnerHTML={{__html: value}}/>
                }
            },
            {
                title: 'Mức giá',
                key: 'count',
                render: (value: string, row: any, index: number) => {
                    const count = prices.filter((x: any) => x.roomId === row._id).length
                    return <a onClick={() => onPriceDetail(row._id)}>{count}</a>
                }
            },
            {
                title: 'Ngày hết giá',
                key: 'date',
                render: (value: string, row: any, index: number) => {
                    const ps = prices.filter((x: any) => x.roomId === row._id);
                    return ps && ps.length > 0 ? moment(ps[0].toDate).format('DD/MM/YYYY') : '---';
                }
            },
            {
                title: 'Kích hoạt',
                key: 'active',
                render: (value: string, row: any, index: number) => {
                    return <Status status={row.status}/>
                }
            },
        ];
        return <div key={`${group._id}-child`}>
            <Table pagination={false} columns={cols} rowKey={(r: any) => `${r._id}_${Math.random()}`}
                   dataSource={group.rooms}/>
        </div>
    }

    return priceGroups ? (
        <>
            <Content>
                <Breadcrumb style={{marginBottom: 10}}>
                    <Breadcrumb.Item>Khách sạn</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/hotel/list'}>Danh sách khách sạn</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="">Nhóm giá</a>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <PanelActions>
                    <PanelAction onClick={() => onOpenModal()} title={'Thêm nhóm giá'}
                                 icon={<i className="fas fa-plus"/>}/>
                </PanelActions>

                <Table bordered columns={columns} rowKey={(row) => row._id} dataSource={priceGroups} expandable={{
                    expandedRowRender: renderRooms,
                }}/>
            </Content>
            <Form onChange={event => autoUpdateName()} form={form} layout="vertical" className='form-vertical'>
                <Modal width={800} title={actionType === 'ADD' ? `Tạo mới nhóm giá` : `Cập nhật giá`}
                       visible={isModalVisible}
                       onOk={handleOk}
                       onCancel={handleCancel}>

                    <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}} extra={promotionMessage} name={'name'}
                               label={'Tên nhóm giá'}>
                        <Input/>
                    </Form.Item>

                    <Form.Item name={'dateRange'} style={{marginBottom: 0}}>
                        <DateRangePicker fields={['fromDate', 'toDate']} form={form}
                                         label={'Khoảng thời gian'}/>
                    </Form.Item>

                    <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}} name={['daysOfWeek']}
                               label={'Ngày áp dụng'}>
                        <Checkbox.Group>
                            {
                                [-1, 1, 2, 3, 4, 5, 6, 7].map((item: any, index: number) => {
                                    return <Checkbox onChange={e => {
                                        const value: any = form.getFieldsValue();
                                        if (e.target.value === -1) {
                                            setTimeout(() => {
                                                const value: any = form.getFieldsValue();
                                                value.daysOfWeek = e.target.checked ? [-1, 1, 2, 3, 4, 5, 6, 7] : [];
                                                form.setFieldsValue(value);
                                            }, 100)
                                        } else {
                                            setTimeout(() => {
                                                const value: any = form.getFieldsValue();
                                                const arr = (value.daysOfWeek || []).filter((x: any) => x >= 1);
                                                const isAll = arr.length === 7;
                                                value.daysOfWeek = isAll ? [-1, 1, 2, 3, 4, 5, 6, 7] : arr;
                                                form.setFieldsValue(value);
                                            }, 100)
                                        }

                                    }
                                    } key={item} value={item}
                                                     style={{lineHeight: '32px'}}>{
                                        item > 1 ? `T${item}` : (item === 1 ? 'CN' : 'Tất cả')
                                    }</Checkbox>
                                })
                            }
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}} name={'typeId'} label={'Loại KM'}>
                        <Radio.Group onChange={e => {
                            setGroupTypeId(e.target.value);
                        }}>
                            {
                                priceGroupTypes.map((value: any, index: number) => {
                                    return (
                                        <Radio.Button key={index.toString()}
                                                      value={value._id}>{value.name.replace(' - Giảm {discountValue}{discountUnit}', '')}</Radio.Button>
                                    )
                                })
                            }
                        </Radio.Group>
                    </Form.Item>

                    {
                        getFields().includes('fixedDays') ? (
                            <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}} name={['value', 'fixedDays']}
                                       label={'Đêm cố định'}>
                                <InputNumber style={{width: '100%'}} min={0} max={500}/>
                            </Form.Item>
                        ) : null
                    }
                    {
                        getFields().includes('freeDays') ? (
                            <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}} name={['value', 'freeDays']}
                                       label={'Đêm miễn phí'}>
                                <InputNumber style={{width: '100%'}} min={0} max={500}/>
                            </Form.Item>
                        ) : null
                    }
                    {
                        getFields().includes('beforeDays') ? (
                            <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}}
                                       name={['value', 'beforeDays']} label={'Đêm đặt trước'}>
                                <InputNumber style={{width: '100%'}} min={0} max={500}/>
                            </Form.Item>
                        ) : null
                    }
                    {
                        getFields().includes('minDays') ? (
                            <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}} name={['value', 'minDays']}
                                       label={'Đêm tối thiểu'}>
                                <InputNumber style={{width: '100%'}} min={0} max={500}/>
                            </Form.Item>
                        ) : null
                    }
                    {
                        getFields().includes('lastDays') ? (
                            <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}} name={['value', 'lastDays']}
                                       label={'Đêm phút cuối'}>
                                <InputNumber style={{width: '100%'}} min={0} max={500}/>
                            </Form.Item>
                        ) : null
                    }
                    {
                        getFields().includes('rangeDateCreated') ? (
                            <>
                                <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}}
                                           name={['value', 'rangeDateCreated']}
                                           label={'Thời gian đặt'}>
                                    <DatePicker.RangePicker/>
                                </Form.Item>
                                <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}}
                                           name={['value', 'rangeDateStay']} label={'Thời gian lưu trú'}>
                                    <DatePicker.RangePicker/>
                                </Form.Item>
                            </>
                        ) : null
                    }

                    <Form.Item hidden={true} name={['discount', 'unit']} initialValue={'percent'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}} name={['discount', 'value']}
                               label={'Giảm giá'}>
                        <InputNumber style={{width: '100%'}} min={0} max={100}/>
                    </Form.Item>

                    <Row>
                        <Col span={4}>
                            <label>Phòng áp dụng</label>
                        </Col>
                        <Col span={20}>
                            <p>
                                <Checkbox onChange={e => {
                                    if (e.target.checked) {
                                        const allRoomIds: Array<any> = [];
                                        priceGroups.map((x: any) => x.rooms.map((y: any) => allRoomIds.push(y._id)));
                                        form.setFieldsValue({rooms: allRoomIds})
                                    } else {
                                        form.setFieldsValue({rooms: []})
                                    }
                                }}>Chọn tất cả</Checkbox>
                            </p>
                            <Form.Item name={['rooms']}>
                                <Checkbox.Group>
                                    {
                                        priceGroups.map((p: any, pIndex: number) => {
                                            return (
                                                <div key={pIndex.toString()}>
                                                    <Checkbox>{p.name}</Checkbox>
                                                    <div>
                                                        {
                                                            p.rooms.map((item: any, index: number) => {
                                                                return <Checkbox key={item._id} value={item._id}
                                                                                 style={{lineHeight: '32px'}}>{item.name.vi}</Checkbox>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>

                    </Row>

                </Modal>
            </Form>
        </>
    ) : null
}
