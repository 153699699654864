import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber, message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Table, Tag, Timeline
} from "antd";
import Content from "../../../components/Containers/Content";
import Panel from "../../../components/Containers/Panel";
import {Link, useHistory, useParams} from "react-router-dom";
import PriceService from "../../../services/price.service";
import {MinusCircleOutlined, PlusCircleOutlined, PlusOutlined} from "@ant-design/icons"
import PanelActions from "../../../components/Containers/PanelActions";
import PanelAction from "../../../components/Elements/PanelAction";
import PriceInput from "../../../components/PriceInput";
import RoomService from "../../../services/room.service";
import lodash from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import Status from "../../../components/Status";
import DateRangePicker from "../../../components/DateRangePicker";
import {FormLayout} from "../../../constants/formLayout";
import "./style.scss";
import PriceGroupService from "../../../services/priceGroup.service";
import HotelService from "../../../services/hotel.service";
import PanelSearch from "../../../components/Containers/PanelSearch";

export default function DetailPricePage(props: any) {
    const {hotelId, roomId}: any = useParams();
    const history = useHistory();
    const [data, setData]: any = useState([]);
    const [priceGroups, setPriceGroups]: any = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selected, setSelected]: any = useState();
    const [actionType, setActionType] = useState('ADD');
    const [loading, setLoading] = useState(true);
    const [selectedRows, setSelectedRows]: any = useState([]);
    const [hotel, setHotel]: any = useState();
    const [form] = Form.useForm();

    const defaultFormValues: any = {
        fromDate: moment(),
        toDate: moment().add(30, 'days'),
        value: {
            daily: {
                price: 0,
                contract: 0,
                commission: 120000,
            },
            weekend: {
                price: 0,
                contract: 0,
                commission: 120000,
            },
        },
        status: 'inactive',
    };
    useEffect(() => {
        initData();
        onListPrice();
    }, []);

    const onListPrice = (page: number = 0, filters: any = null) => {
        setLoading(true);
        PriceService.list(hotelId, page, filters).then(value => {
            setData(value);
        }).finally(() => {
            setLoading(false);
        });
    }

    const initData = () => {
        PriceGroupService.list(hotelId).then(value => {
            if (value) {
                setPriceGroups(value);
            }
        });
        HotelService.get(hotelId).then(value => {
            if (value) {
                setHotel(value);
            }
        })
    }

    const columns = [
        {
            title: 'Từ ngày',
            dataIndex: 'fromDate',
            key: 'fromDate',
            render: (value: string, row: any, index: number) => {
                return moment(value).format('DD/MM/YYYY')
            }
        },
        {
            title: 'Đến ngày',
            dataIndex: 'toDate',
            key: 'toDate',
            render: (value: string, row: any, index: number) => {
                return moment(value).format('DD/MM/YYYY')
            }
        },
        {
            title: 'Ngày áp dụng',
            dataIndex: 'daysOfWeek',
            key: 'daysOfWeek',
            render: (value: Array<number>, row: any, index: number) => {
                return value.map((value1, index1) => {
                    return <Tag key={index1}>T{value1}</Tag>
                })
            }
        },
        {
            title: 'Giá thường',
            dataIndex: ['value', 'daily', 'price'],
            key: 'daily-price',
            render: (value: string, row: any, index: number) => {
                return <NumberFormat displayType={'text'} thousandSeparator={true} value={value}/>
            }
        },
        {
            title: 'Giá cuối tuần',
            dataIndex: ['value', 'weekend', 'price'],
            key: 'weekend-price',
            render: (value: string, row: any, index: number) => {
                return <NumberFormat displayType={'text'} thousandSeparator={true} value={value}/>
            }
        },
        {
            title: 'Hoa hồng',
            key: 'commission',
            render: (value: string, row: any, index: number) => {
                return <span>
                    <NumberFormat displayType={'text'} thousandSeparator={true}
                                  value={row.value.daily.commission || 0}/>
                    -
                    <NumberFormat displayType={'text'} thousandSeparator={true}
                                  value={row.value.weekend.commission || 0}/>
                </span>
            }
        },

        {
            title: 'Giá thường HĐ',
            dataIndex: ['value', 'daily', 'contract'],
            key: 'daily-contract',
            render: (value: string, row: any, index: number) => {
                return <NumberFormat displayType={'text'} thousandSeparator={true} value={value}/>
            }
        },
        {
            title: 'Giá cuối tuần HĐ',
            dataIndex: ['value', 'weekend', 'contract'],
            key: 'weekend-contract',
            render: (value: string, row: any, index: number) => {
                return <NumberFormat displayType={'text'} thousandSeparator={true} value={value}/>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (value: string, row: any, index: number) => {
                return <Status status={value}/>
            }
        },
        {
            title: 'Chức năng',
            dataIndex: 'actions',
            key: 'actions',
            render: (value: string, row: any, index: number) => {
                return (
                    <Space size="middle">
                        <Button key={1} onClick={event => openModalUpdate(row)} type="primary" size="small"
                                ghost>Sửa</Button>
                    </Space>
                )
            }
        },
    ];

    const handleOk = () => {
        const value = form.getFieldsValue();
        if (!value.daysOfWeek) {
            value.daysOfWeek = [];
        }
        if (actionType === "ADD") {
            if (!value.rooms || value.rooms.length === 0) {
                message.error('Chưa chọn phòng');
                return;
            }
            if ((!value.ranges || value.ranges.length === 0)) {
                message.error('Chưa chọn giai đoạn');
                return;
            }

            handleAdd(value).then();
        }
        if (actionType === "UPDATE") {
            handleUpdate(value).then();
        }
        setIsModalVisible(false);
    };

    const handleCancel = async () => {
        setIsModalVisible(false);
    };

    const onOpenModal = () => {
        setIsModalVisible(true);
        setActionType('ADD')
        form.setFieldsValue(defaultFormValues);
    }

    const openModalUpdate = (row: any) => {
        setIsModalVisible(true);
        setSelected(row);
        setActionType('UPDATE')
        form.setFieldsValue({
            ...row,
            fromDate: moment(row.fromDate),
            toDate: moment(row.toDate),
            dateRange: [moment(row.fromDate), moment(row.toDate)]
        });
    }

    const handleAdd = async (item: any) => {
        await PriceService.createAuto({
            hotelId,
            daysOfWeek: item.daysOfWeek.filter((x: any) => x >= 1).sort(),
            ...item,
        });
        onListPrice();
    }

    const handleUpdate = async (values: any) => {
        await PriceService.createAuto({
            hotelId,
            ...values,
            rooms: [values.roomId],
            ranges: [{dateRange: [values.fromDate, values.toDate]}],
            daysOfWeek: values.daysOfWeek.filter((x: any) => x >= 1).sort(),
        });
        onListPrice();
    }

    const handleRemove = async () => {
        await PriceService.bulk(selectedRows.map((r: any) => {
            return {
                type: 'delete',
                value: {_id: r._id}
            }
        }));
        onListPrice();
    }


    const onSelectRowChange = (keys: any, rows: Array<any>) => {
        setSelectedRows(rows);
    };

    const onSearch = (values: any) => {
        onListPrice(0, values);
    };

    return (
        <>
            <Content>
                <Breadcrumb style={{marginBottom: 10}}>
                    <Breadcrumb.Item>Khách sạn</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/hotel/list'}>{hotel ? hotel.name.vi : ''}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/price/${hotelId}`}>Quản lý giá phòng</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>Thông tin giá ()</a>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <PanelActions>
                    <PanelAction onClick={() => onOpenModal()} title={'Thêm giá'} icon={<i className="fas fa-plus"/>}/>
                    <Popconfirm title={'Bạn có chắc xóa giá?'} onConfirm={e => handleRemove()}>
                        <PanelAction title={'Xóa'} icon={<i className="fas fa-trash-alt"/>}/>
                    </Popconfirm>
                </PanelActions>

                <PanelSearch>
                    <Form layout={'inline'} onFinish={onSearch}>
                        <Form.Item label={'Chọn ngày'} name={'date'}>
                            <DatePicker allowClear size={'small'} format={'DD/MM/YYYY'}/>
                        </Form.Item>
                        <Form.Item label={'Giá'} name={'price'}>
                            <PriceInput allowClear size={'small'}/>
                        </Form.Item>
                        <Form.Item>
                            <Button size={"small"} htmlType={'submit'} type="primary">Tìm kiếm</Button>
                        </Form.Item>
                    </Form>
                </PanelSearch>
                <Table loading={loading} bordered columns={columns} rowKey={(row) => row._id}
                       dataSource={data.filter((x: any) => x.roomId === roomId)} rowSelection={
                    {
                        type: 'checkbox',
                        onChange: onSelectRowChange
                    }
                }/>
            </Content>

            <Modal width={900} title={actionType === 'ADD' ? `Tạo mới trường giá` : `Cập nhật giá`}
                   visible={isModalVisible}
                   onOk={handleOk}
                   onCancel={handleCancel}>
                <Form form={form} className='form-vertical'
                      initialValues={defaultFormValues}>


                    {
                        actionType === 'ADD' ? (
                            <Form.List name="ranges" initialValue={[{dateRange: null}]}>
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, fieldKey, ...restField}) => (
                                            <div key={key} className={'row-price'}>
                                                <div className={'row-action-price-main'}>
                                                    <Form.Item className={'row-action-price'} labelCol={{span: 5}}
                                                               wrapperCol={{span: 19}}
                                                               name={[name, 'dateRange']}
                                                               label={`Giai đoạn ${name + 1}`}>
                                                        <DatePicker.RangePicker tabIndex={0} style={{width: '100%'}}
                                                                                format={'DD/MM/YYYY'}/>
                                                    </Form.Item>

                                                    {
                                                        fields.length !== 1 ? (
                                                                <MinusCircleOutlined onClick={() => remove(name)}/>) :
                                                            <span style={{width: 14, height: 14, display: 'block'}}/>
                                                    }
                                                </div>
                                                {
                                                    (name + 1) === fields.length ? (
                                                        <Button type="primary" ghost={true} onClick={() => add()}
                                                                icon={<PlusOutlined/>}/>
                                                    ) : <div style={{width: 80}}/>
                                                }
                                            </div>
                                        ))}

                                    </>
                                )}
                            </Form.List>
                        ) : (
                            <Form.Item name={'dateRange'}>
                                <DateRangePicker layout={true} fields={['fromDate', 'toDate']} form={form}
                                                 label={'Khoảng thời gian'}/>
                            </Form.Item>
                        )
                    }

                    <Form.Item labelCol={{span: 4}} wrapperCol={{span: 20}} name={['daysOfWeek']}
                               label={'Ngày áp dụng'}>
                        <Checkbox.Group>
                            {
                                [-1, 1, 2, 3, 4, 5, 6, 7].map((item: any, index: number) => {
                                    return <Checkbox onChange={e => {
                                        const value: any = form.getFieldsValue();
                                        if (e.target.value === -1) {
                                            setTimeout(() => {
                                                const value: any = form.getFieldsValue();
                                                value.daysOfWeek = e.target.checked ? [-1, 1, 2, 3, 4, 5, 6, 7] : [];
                                                form.setFieldsValue(value);
                                            }, 100)
                                        } else {
                                            setTimeout(() => {
                                                const value: any = form.getFieldsValue();
                                                const arr = (value.daysOfWeek || []).filter((x: any) => x >= 1);
                                                const isAll = arr.length === 7;
                                                value.daysOfWeek = isAll ? [-1, 1, 2, 3, 4, 5, 6, 7] : arr;
                                                form.setFieldsValue(value);
                                            }, 100)
                                        }

                                    }
                                    } key={item} value={item}
                                                     style={{lineHeight: '32px'}}>{
                                        item > 1 ? `T${item}` : (item === 1 ? 'CN' : 'Tất cả')
                                    }</Checkbox>
                                })
                            }
                        </Checkbox.Group>
                    </Form.Item>
                    <Row>
                        <Col span={11}>
                            <Form.Item labelCol={{span: 10}} wrapperCol={{span: 14}}
                                       name={['value', 'daily', 'contract']} label="Giá thường HĐ">
                                <PriceInput tabIndex={1} onChange={(e: any) => {
                                    form.setFieldsValue({
                                        value: {
                                            daily: {
                                                price: e + form.getFieldValue(['value', 'daily', 'commission'])
                                            }
                                        }
                                    });
                                }}/>
                            </Form.Item>
                            <Form.Item labelCol={{span: 10}} wrapperCol={{span: 14}}
                                       name={['value', 'daily', 'commission']} label="Hoa hồng thường">
                                <PriceInput tabIndex={3} onChange={(e: any) => {
                                    form.setFieldsValue({
                                        value: {
                                            daily: {
                                                price: form.getFieldValue(['value', 'daily', 'contract']) + e
                                            }
                                        }
                                    });
                                }}/>
                            </Form.Item>
                            <Form.Item labelCol={{span: 10}} wrapperCol={{span: 14}} name={['value', 'daily', 'price']}
                                       label="Giá thường">
                                <PriceInput tabIndex={5}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}/>
                        <Col span={11}>
                            <Form.Item labelCol={{span: 10}} wrapperCol={{span: 14}}
                                       name={['value', 'weekend', 'contract']} label="Giá cuối tuần HĐ">
                                <PriceInput tabIndex={2} onChange={(e: any) => {
                                    form.setFieldsValue({
                                        value: {
                                            weekend: {
                                                price: e + form.getFieldValue(['value', 'weekend', 'commission'])
                                            }
                                        }
                                    });
                                }}/>
                            </Form.Item>
                            <Form.Item labelCol={{span: 10}} wrapperCol={{span: 14}}
                                       name={['value', 'weekend', 'commission']} label="Hoa hồng cuối tuần">
                                <PriceInput tabIndex={4} onChange={(e: any) => {
                                    form.setFieldsValue({
                                        value: {
                                            weekend: {
                                                price: form.getFieldValue(['value', 'weekend', 'contract']) + e
                                            }
                                        }
                                    });
                                }}/>
                            </Form.Item>
                            <Form.Item labelCol={{span: 10}} wrapperCol={{span: 14}}
                                       name={['value', 'weekend', 'price']} label="Giá cuối tuần">
                                <PriceInput tabIndex={6}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item name={['status']} label="Trạng thái">
                        <Select style={{width: '100%'}}>
                            <Select.Option value="inactive">Không hoạt động</Select.Option>
                            <Select.Option value="active">Hoạt động</Select.Option>
                        </Select>
                    </Form.Item>


                    {
                        actionType === 'ADD' ? (
                            <div>
                                <label>Phòng áp dụng</label>
                                <p>
                                    <Checkbox onChange={e => {
                                        if (e.target.checked) {
                                            const allRoomIds: Array<any> = [];
                                            priceGroups.map((x: any) => x.rooms.map((y: any) => allRoomIds.push(y._id)));
                                            form.setFieldsValue({rooms: allRoomIds})
                                        } else {
                                            form.setFieldsValue({rooms: []})
                                        }
                                    }}>Chọn tất cả</Checkbox>
                                </p>
                                <Form.Item name={['rooms']}>
                                    <Checkbox.Group>
                                        {
                                            priceGroups.map((p: any, pIndex: number) => {
                                                return (
                                                    <div key={pIndex.toString()}>
                                                        <Checkbox>{p.name}</Checkbox>
                                                        <div>
                                                            {
                                                                p.rooms.map((item: any, index: number) => {
                                                                    return <Checkbox key={item._id} value={item._id}
                                                                                     style={{lineHeight: '32px'}}>{item.name.vi}</Checkbox>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Checkbox.Group>
                                </Form.Item>

                            </div>
                        ) : <Form.Item hidden={true} name={'roomId'}>
                            <Input/>
                        </Form.Item>
                    }
                </Form>
            </Modal>
        </>
    )
}
