import HttpService from "./http.service";
import Environment from "../environment";

export default class PriceGroupService {
    static find(query: any) {
        return HttpService.post(Environment.host.concat('/price-group/find'), query);
    }

    static list(hotelId: string) {
        return HttpService.get(Environment.host.concat(`/price-group/${hotelId}`));
    }

    static add(p: any) {
        return HttpService.post(Environment.host.concat('/price-group'), p);
    }

    static update(_id: string, price: any) {
        return HttpService.put(Environment.host.concat(`/price-group/${_id}`), price);
    }

    static remove(_id: string) {
        return HttpService.delete(Environment.host.concat(`/price-group/${_id}`));
    }
}
