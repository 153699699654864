import HttpService from "./http.service";
import Environment from "../environment";

export default class PropertyService {
    static find(query: any) {
        return HttpService.post(Environment.host.concat('/property/find'), query);
    }

    static list(type: string) {
        return HttpService.get(Environment.host.concat(`/property/list/${type}`));
    }

    static add(p: { type: string, value: any }) {
        return HttpService.post(Environment.host.concat('/property'), p);
    }

    static update(_id: string, p: { type: string, value: any }) {
        return HttpService.put(Environment.host.concat(`/property/${_id}`), p);
    }

    static remove(_id: string) {
        return HttpService.delete(Environment.host.concat(`/property/${_id}`));
    }

    static listByGroup(types: Array<string>) {
        return HttpService.get(Environment.host.concat(`/property/by-group`), {
            types: types.join(',')
        });
    }
}
