import React, {useEffect, useState} from "react";
import {Menu} from "antd";
import {
    CarryOutOutlined,
    UserOutlined,
    DatabaseOutlined,
    PicRightOutlined,
    FlagOutlined,
    SendOutlined,
    UsergroupAddOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {Link, useLocation} from "react-router-dom";
import "./style.scss";

const {SubMenu} = Menu;

const menus = [
    {name: 'Bảng điều khiển', icon: (<UserOutlined/>), link: '/'},
    {
        name: 'Khách sạn', icon: (<PicRightOutlined/>), link: '/hotel', subMenus: [
            {name: 'Khách sạn', link: '/hotel/list'},
            {name: 'Tiện nghi', link: '/properties/amenity'},
            {name: 'Nhóm tiện nghi', link: '/properties/amenity-category'},
            {name: 'Đặc điểm', link: '/properties/feature'},
            {name: 'Quyền lợi', link: '/properties/benefit'},
            {name: 'Loại giường', link: '/properties/bed'},
            {name: 'Quốc gia', link: '/properties/country'},
            {name: 'Thành phố', link: '/properties/city'},
            {name: 'Địa điểm', link: '/properties/place'},
            {name: 'Mẫu nhóm giá', link: '/properties/price-group'},
        ]
    },
    {name: 'Vé máy bay', icon: (<SendOutlined/>), link: '/1'},
    {name: 'Tour du lịch', icon: (<FlagOutlined/>), link: '/2'},
    {name: 'Đơn đặt hàng', icon: (<CarryOutOutlined/>), link: '/3'},
    {name: 'Khách hàng', icon: (<UsergroupAddOutlined/>), link: '/4'},
    {name: 'Cài đặt', icon: (<SettingOutlined/>), link: '/5'},
]

export default function Navigation(props: any) {
    let location = useLocation();
    return (
        <div className={'nav-view'}>
            <div className={'nav-view-head'}>
                <div className={'nav-view-head-left'}>
                    <img alt="logo" src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"/>
                    <h1>TrippyAsia</h1>
                </div>
                <div className={'nav-view-head-right'}>
                    <h3>Xin chào, admin</h3>
                    <a>đăng xuất</a>
                </div>
            </div>
            <Menu mode="horizontal" defaultSelectedKeys={['1']} theme="dark" selectedKeys={[location.pathname]}>
                {
                    menus.map((value, index, array) => {
                        if (value.subMenus) {
                            return (
                                <SubMenu key={value.link} icon={value.icon} title={value.name}>
                                    {
                                        value.subMenus.map((value1: any, index1: number) => (
                                            <Menu.Item key={value1.link}>
                                                <Link to={value1.link}>{value1.name}</Link>
                                            </Menu.Item>
                                        ))
                                    }
                                </SubMenu>
                            )
                        } else {
                            return (
                                <Menu.Item key={value.link} icon={value.icon}>
                                    <Link to={value.link}>{value.name}</Link>
                                </Menu.Item>
                            )
                        }
                    })
                }
            </Menu>
        </div>
    )
}
