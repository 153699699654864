import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Form,
    Input,
    PageHeader,
    Rate,
    Select,
    Space,
    Table,
    TimePicker,
    Checkbox,
    InputNumber,
    message,
    DatePicker,
    Row, Col, Breadcrumb
} from "antd";
import Content from "../../../components/Containers/Content";
import Panel from "../../../components/Containers/Panel";
import PanelTitle from "../../../components/Elements/PanelTitle";
import "./style.scss";
import PropertyService from "../../../services/property.service";
import {MinusCircleOutlined, CloseCircleOutlined, PlusOutlined} from '@ant-design/icons';
import UploadImages from "../../../components/UploadImages";
import RoomService from "../../../services/room.service";
import {Link, useHistory, useParams} from "react-router-dom";
import MceEditor from "../../../components/MceEditor";
import PriceService from "../../../services/price.service";
import moment from "moment";
import DatePickerForm from "../../../components/DatePicker";
import {convertPropertyToIdName} from "../../../services/app.service";

export default function AddRoomPage(props: any) {
    const [beds, setBeds]: any = useState([]);
    const [amenities, setAmenities]: any = useState([]);
    const [amenityCategories, setAmenityCategories]: any = useState([]);
    const [benefits, setBenefits]: any = useState([]);
    const [room, setRoom]: any = useState();
    const {hotelId, roomId}: any = useParams();
    const history = useHistory();
    const [form] = Form.useForm();

    const onSubmit = (values: any) => {
        if (roomId) {
            RoomService.update(roomId, {hotelId, ...values}).then(value => {
                if (value) {
                    message.success('Cập nhật thành công!');
                    history.goBack();
                }
            });
        } else {
            RoomService.add({hotelId, ...values}).then(value => {
                if (value) {
                    message.success('Thêm thành công!');
                    history.goBack();
                }
            });
        }
    }

    useEffect(() => {
        if (roomId) {
            RoomService.get(roomId).then(value => {
                if (value) {
                    setRoom(value);
                    form.setFieldsValue(value);
                }
            });
        }
    }, [roomId]);

    useEffect(() => {
        getInit().then()
    }, []);


    const getInit = async () => {
        let result: any = await PropertyService.listByGroup(['bed', 'amenity', 'amenity-category', 'benefit']).catch(reason => []);
        setBeds(convertPropertyToIdName(result['bed']));
        setAmenities(convertPropertyToIdName(result['amenity'], 'vi', {amenityCategoryId: 'amenityCategoryId'}));
        setAmenityCategories(convertPropertyToIdName(result['amenity-category'], 'vi'));
        setBenefits(convertPropertyToIdName(result['benefit'], 'vi'));
    }

    return (
        <>
            <Form form={form} initialValues={{remember: true}} layout="vertical" onFinish={onSubmit}>
                <Content>
                    <Breadcrumb style={{marginBottom: 10}}>
                        <Breadcrumb.Item>Khách sạn</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/hotel/list">Danh sách khách sạn</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a onClick={event => history.goBack()}>Danh sách phòng</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a>Chi tiết phòng</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Panel key={1}>
                        <PanelTitle>Thông tin cơ bản</PanelTitle>

                        <Form.Item key={1} label="Tên phòng (vi/en)">
                            <Input.Group compact>
                                <Form.Item key={1} name={['name', 'vi']} noStyle>
                                    <Input style={{width: '50%'}} placeholder="Nhập tên phòng..."/>
                                </Form.Item>
                                <Form.Item key={2} name={['name', 'en']} noStyle>
                                    <Input style={{width: '50%'}} placeholder="Input room name..."/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        <Form.Item key={2} label="Mô tả ngắn (vi/en)">
                            <Input.Group compact>
                                <Form.Item key={1} name={['description', 'vi']} noStyle>
                                    <MceEditor/>
                                </Form.Item>
                                <Form.Item key={2} name={['description', 'en']} noStyle>
                                    <MceEditor/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        <Row>
                            <Col key={1} span={4}>
                                <Form.Item name={['capacity', 'adult']} label="Sức chứa (người lớn)">
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col key={2} span={1}/>
                            <Col span={4}>
                                <Form.Item name={['capacity', 'children']} label="Sức chứa (trẻ em)">
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col key={3} span={1}/>
                            <Col span={4}>
                                <Form.Item name={['acreage']} label="Diện tích (m2)">
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col key={4} span={1}/>
                            <Col key={5} span={4}>
                                <Form.Item name={['extraBed']} label="Giá giường phụ">
                                    <InputNumber
                                        style={{width: '100%'}}
                                        formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col key={6} span={1}/>
                            <Col key={7} span={4}>
                                <Form.Item name={['surcharge', 'children']} label="Giá phụ thu(trẻ em)">
                                    <InputNumber
                                        style={{width: '100%'}}
                                        formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row>
                            {
                                beds.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <Col span={4} key={index.toString()}>
                                                <Form.Item label={`Số lượng giường (${item.name})`} key={1}
                                                           name={['bed', index, 'quantity']}>
                                                    <InputNumber style={{width: '100%'}}/>
                                                </Form.Item>
                                                <Form.Item key={2} name={['bed', index, '_id']} initialValue={item._id}
                                                           style={{display: 'none'}}>
                                                    <input type='hidden'/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}/>
                                        </>
                                    )
                                })
                            }
                        </Row>
                    </Panel>

                    <Panel key={2}>
                        <PanelTitle>Hình ảnh hiển thị</PanelTitle>
                        <div>
                            <label>Hình ảnh chính</label>
                            <Form.Item label="Hình ảnh chính" name={['images']} noStyle>
                                <UploadImages/>
                            </Form.Item>
                        </div>
                    </Panel>
                    <Panel key={3}>
                        <PanelTitle>Tiện nghi</PanelTitle>
                        <div>
                            <Form.Item name={['amenities']}>
                                <Checkbox.Group>
                                    {
                                        amenityCategories.map((amenityCategory: any, indexAmenityCategory: number) => {
                                            const values = amenities.filter((x: any) => x.amenityCategoryId === amenityCategory._id);
                                            return values.length > 0 ? (
                                                <div key={indexAmenityCategory.toString()} className={'amenities'}>
                                                    <h5 className={'amenity-title'}>{amenityCategory.name}</h5>
                                                    <div>
                                                        {
                                                            values.map((item: any, index: number) => {
                                                                return <Checkbox key={item._id} value={item._id}
                                                                                 style={{lineHeight: '32px'}}>{item.name}</Checkbox>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            ) : null
                                        })
                                    }


                                </Checkbox.Group>
                            </Form.Item>
                        </div>
                    </Panel>

                    <Panel key={3.5}>
                        <PanelTitle>Quyền lợi</PanelTitle>
                        <Form.List name={["benefits"]}>
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map(({key, name, fieldKey, ...restField}) => (
                                        <Space key={key} direction={"vertical"}>
                                            <Row>
                                                <Col span={10}>
                                                    <Form.Item label={'Áp dụng từ (ngày)'} name={[name, 'fromDate']}
                                                               fieldKey={[fieldKey, 'fromDate']}>
                                                        <DatePickerForm/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}/>
                                                <Col span={10}>
                                                    <Form.Item label={'Áp dụng đến (ngày)'} name={[name, 'toDate']}
                                                               fieldKey={[fieldKey, 'toDate']}>
                                                        <DatePickerForm/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Form.Item label={'Chọn ngày trong tuần'} name={[name, 'dayOfWeek']}
                                                       fieldKey={[fieldKey, 'dayOfWeek']}>
                                                <Checkbox.Group>
                                                    {
                                                        [1, 2, 3, 4, 5, 6, 7].map((item: any, index: number) => {
                                                            return <Checkbox key={item._id} value={item}
                                                                             style={{lineHeight: '32px'}}>T{item + 1}</Checkbox>
                                                        })
                                                    }
                                                </Checkbox.Group>
                                            </Form.Item>
                                            <Form.Item label={'Lựa chọn quyền lợi'} name={[name, 'values']}
                                                       fieldKey={[fieldKey, 'values']}>
                                                <Checkbox.Group>
                                                    {
                                                        benefits.map((item: any, index: number) => {
                                                            return <Checkbox key={item._id} value={item._id}
                                                                             style={{lineHeight: '32px'}}>{item.name}</Checkbox>
                                                        })
                                                    }
                                                </Checkbox.Group>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)}/>
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} style={{width: '60%'}}
                                                icon={<PlusOutlined/>}>
                                            Thêm quyền lợi
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Panel>

                    <Panel key={4}>
                        <PanelTitle>Chính sách/qui định</PanelTitle>
                        <div>
                            <Form.Item key={1} label="Hủy phòng (vi/en)">
                                <Input.Group compact>
                                    <Form.Item name={['policies', 'cancel', 'vi']} noStyle>
                                        <MceEditor/>
                                    </Form.Item>
                                    <Form.Item name={['policies', 'cancel', 'en']} noStyle>
                                        <MceEditor/>
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>

                            <Form.Item key={2} label="Thêm người/trẻ em (vi/en)">
                                <Input.Group compact>
                                    <Form.Item name={['policies', 'extraPerson', 'vi']} noStyle>
                                        <MceEditor/>
                                    </Form.Item>
                                    <Form.Item name={['policies', 'extraPerson', 'en']} noStyle>
                                        <MceEditor/>
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </div>
                    </Panel>
                </Content>

                <Content>
                    <div className={'form-actions'}>
                        <Button size={"large"} htmlType={"submit"} type="primary">Lưu lại</Button>
                        <Button size={"large"} onClick={event => history.goBack()}>Hủy bỏ</Button>
                    </div>
                </Content>
            </Form>
        </>
    )
}
