import React from "react";
import PropertyPage from "../index";
import {Col, DatePicker, Form, Input, InputNumber, Row, Select, Tag} from "antd";
import UploadFileContent from "../../../components/UploadFileContent";

const FieldsPriceGroup = [
    {name: 'Đêm tối thiểu', id: 'minDays'},
    {name: 'Đêm cố định', id: 'fixedDays'},
    {name: 'Đêm miễn phí', id: 'freeDays'},
    {name: 'Đêm đặt trước', id: 'beforeDays'},
    {name: 'Đêm phút cuối', id: 'lastDays'},
    {name: 'Khoảng thời gian đặt phòng', id: 'rangeDateCreated'},
    {name: 'Khoảng thời gian lưu trú', id: 'rangeDateStay'},
]

export default function PriceGroupPage(props: any) {
    const columns = [
        {
            title: 'Icon',
            dataIndex: 'icon',
            key: 'icon',
            render: (value: any, item: any, index: number) => <img style={{width: 28, height: 'auto'}}
                                                                   src={item.value.icon}/>,
        },
        {
            title: 'Tên nhóm',
            dataIndex: ['value', 'name'],
            key: 'value',
            render: (value: any, item: any, index: any) => {
                return <b
                          dangerouslySetInnerHTML={{__html: value.split('{').join('<b class="tag-view">{').split('}').join('}</b>')}}/>
            },
        },
        {
            title: 'Mẫu khuyễn mãi(vi)',
            dataIndex: ['value', 'template', ['vi']],
            key: 'template-vi',
            render: (value: string, item: any, index: any) => {
                return <span
                    dangerouslySetInnerHTML={{__html: value.split('{').join('<b class="tag-view">{').split('}').join('}</b>')}}/>
            },
        },
        {
            title: 'Tên phòng',
            dataIndex: ['value', 'roomName'],
            key: 'roomName',
            render: (value: string, item: any, index: any) => {
                return <span
                    dangerouslySetInnerHTML={{__html: value.split('{').join('<b class="tag-view">{').split('}').join('}</b>')}}/>
            },
        },
        {
            title: 'Trường dữ liệu',
            dataIndex: ['value', 'fields'],
            key: 'fields',
            render: (value: any, item: any, index: any) => {
                return value ? value.map((v: any, i: number) => {
                    return <Tag>{v}</Tag>
                }) : null
            }
        },
        {
            title: 'Công thức',
            dataIndex: ['value', 'formula'],
            key: 'formula',
            render: (value: any, item: any, index: any) => {
                return <b dangerouslySetInnerHTML={{__html: (value || '').split('{').join('<b class="tag-view">{').split('}').join('}</b>')}}/>
            },
        },
    ]

    return (
        <>
            <PropertyPage modalWidth={800} title='Quản lý danh mục nhóm giá' desc="Khách sạn" type='price-group'
                          columns={columns}
            >
                <Form.Item name={['name']} label="Tên nhóm">
                    <Input placeholder=""/>
                </Form.Item>
                <Form.Item name={['template', 'vi']} label="Mẫu khuyễn mãi(vi)">
                    <Input.TextArea rows={2} placeholder=""/>
                </Form.Item>
                <Form.Item name={['template', 'en']} label="Mẫu khuyễn mãi(en)">
                    <Input.TextArea rows={2} placeholder=""/>
                </Form.Item>
                <Form.Item name={['formula']} label="Công thức tính giá">
                    <Input placeholder=""/>
                </Form.Item>
                <Form.Item name={['roomName']} label="Tên phòng">
                    <Input placeholder=""/>
                </Form.Item>
                <Form.Item name={['fields']} label="Trường dữ liệu" initialValue={[]}>
                    <Select mode="multiple" allowClear>
                        {FieldsPriceGroup.map((value, index) => (
                            <Select.Option key={value.id} value={value.id}
                                           title={value.name}>{value.name}</Select.Option>))}
                    </Select>
                </Form.Item>
                <Form.Item label="Chọn icon">
                    <Form.Item name="icon">
                        <UploadFileContent/>
                    </Form.Item>
                </Form.Item>
                <p style={{fontStyle: '6px', color: "#797979"}}>
                    Các trường thêm: fixedDays freeDays beforeDays lastDays minDays
                    rangeDateCreated rangeDateStay fromDate toDate discountValue discountUnit priceContract</p>
            </PropertyPage>
        </>
    )
}
