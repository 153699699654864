import React from "react";
import PropertyPage from "../index";
import {Form, Input} from "antd";
import UploadFileContent from "../../../components/UploadFileContent";


export default function AmenityCategoryPage(props: any) {
    const columns = [
        {
            title: 'Icon',
            dataIndex: 'icon',
            key: 'icon',
            render: (value: any, item: any, index: number) => <img style={{width: 28, height: 'auto'}}
                                                                   src={item.value.icon}/>,
        },
        {
            title: 'Tên tiếng việt',
            dataIndex: ['value', 'name', 'vi'],
            key: 'value',
        },
        {
            title: 'Tên tiếng anh',
            dataIndex: ['value', 'name', 'en'],
            key: 'value',
        },
    ]

    return (
        <>
            <PropertyPage title='Quản lý danh mục tiện ích' desc="Khách sạn" type='amenity-category' columns={columns}
            >
                <Form.Item name={['name', 'vi']} label="Tên tiếng việt">
                    <Input placeholder="Nhập tên tiếng việt"/>
                </Form.Item>
                <Form.Item name={['name', 'en']} label="Tên tiếng anh">
                    <Input placeholder="Nhập tên tiếng việt"/>
                </Form.Item>
                <Form.Item label="Chọn icon">
                    <Form.Item name="icon">
                        <UploadFileContent/>
                    </Form.Item>
                </Form.Item>
            </PropertyPage>
        </>
    )
}
