import axios from 'axios';
import Environment from "../environment";

const headers = {
    'Authorization': 'Bearer 545',
    "api-key": Environment.API_KEY
};

export default class HttpService {
    static getHeaders() {
        return headers;
    }

    static get(url: string, params: any = {}) {
        return axios.get(url, {
            headers,
            params
        }).then(value => {
            return value.data;
        }).catch(error => {
            return null;
        });
    }

    static post(url: string, data: any, header: any = {}) {
        return axios.post(url, data, {
            headers: {
                ...headers,
                ...header
            },
        }).then(value => {
            return value.data;
        }).catch(error => {
            return null;
        });
    }

    static put(url: string, data: any) {
        return axios.put(url, data, {
            headers,
        }).then(value => {
            return value.data;
        }).catch(error => {
            return null;
        });
    }

    static delete(url: string) {
        return axios.delete(url, {
            headers,
        }).then(value => {
            return value.data;
        }).catch(error => {
            return null;
        });
    }
}
