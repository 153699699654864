import React, {useState} from "react";
import {Alert, message} from 'antd';
import {Form, Input, Button, Checkbox} from 'antd';
import './style.scss';
import {Typography} from 'antd';
import {useDispatch} from "react-redux";
import {actionAuthChange} from "../../redux/actions/auth.action";
import {login} from "../../services/app.service";

const {Title, Paragraph, Text} = Typography;
export default function AuthPage(props: any) {
    const dispatch = useDispatch();

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };
    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    const onFinish = (values: any) => {
        login(values.username, values.password).then((value: any) => {
            if (value) {
                dispatch(actionAuthChange(value));
            } else {
                message.error(value.message || "Cannot login").then();
            }
        }).catch((reason: any) => {
            message.error(reason.message).then();
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleVerify = (v: any) => {
        console.log('Failed:', v);
    };

    return (
        <div className="login-warp">
            <div className="login-title">
                <Title level={3}>Hệ thống TrippyAsia</Title>
                <Paragraph>Vui lòng đăng nhập vào hệ thống để tiếp tục</Paragraph>
            </div>
            <Form
                {...layout}
                name="basic"
                initialValues={{remember: true}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Tên đăng nhập"
                    name="username"
                    rules={[{required: true, message: 'Please input your username!'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Mật khẩu"
                    name="password"
                    rules={[{required: true, message: 'Please input your password!'}]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                    <Checkbox>Ghi nhớ đăng nhập</Checkbox>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Đăng nhập
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
