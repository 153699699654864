import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Space,
    Table, Timeline
} from "antd";
import Content from "../../../components/Containers/Content";
import Panel from "../../../components/Containers/Panel";
import {Link, useHistory, useParams} from "react-router-dom";
import PriceService from "../../../services/price.service";
import {MinusCircleOutlined, PlusCircleOutlined, PlusOutlined} from "@ant-design/icons"
import PanelActions from "../../../components/Containers/PanelActions";
import PanelAction from "../../../components/Elements/PanelAction";
import PriceInput from "../../../components/PriceInput";
import RoomService from "../../../services/room.service";
import lodash from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import Status from "../../../components/Status";
import DateRangePicker from "../../../components/DateRangePicker";
import {FormLayout} from "../../../constants/formLayout";
import "./style.scss";
import PriceGroupService from "../../../services/priceGroup.service";
import HotelService from "../../../services/hotel.service";

export default function ListPricePage(props: any) {
    const {hotelId}: any = useParams();
    const history = useHistory();
    const [prices, setPrices]: any = useState([]);
    const [priceGroups, setPriceGroups]: any = useState([]);
    const [hotel, setHotel]: any = useState();
    useEffect(() => {
        initData();
        onListPrice();
    }, []);

    const onListPrice = () => {
        PriceService.list(hotelId).then(value => {
            setPrices(value);
        });
    }

    const initData = () => {
        PriceGroupService.list(hotelId).then(value => {
            if (value) {
                setPriceGroups(value);
            }
        });
        HotelService.get(hotelId).then(value => {
            if (value) {
                setHotel(value);
            }
        })
    }

    const onDetail = (roomId: string) => {
        history.push(`/price-detail/${hotelId}/${roomId}`)
    }

    const columns = [
        {
            title: 'Nhóm giá',
            dataIndex: ['name'],
            key: 'name',
            render: (value: string, row: any, index: number) => {
                return (
                    <b>{value}</b>
                )
            }
        },
    ];

    const renderPrice = (group: any) => {
        const cols: Array<any> = [
            {
                title: 'Loại phòng',
                dataIndex: ['name', 'vi'],
                key: 'name',
                render: (value: string, row: any, index: number) => {
                    return <span className={`room-name ${value.indexOf('<b>') < 0 ? 'not-name' : ''}`}
                                 dangerouslySetInnerHTML={{__html: value}}/>
                }
            },
            {
                title: 'Mức giá',
                key: 'count',
                render: (value: string, row: any, index: number) => {
                    const count = prices.filter((x: any) => x.roomId === row._id).length
                    return <a onClick={() => onDetail(row._id)}>{count}</a>
                }
            },
            {
                title: 'Ngày hết giá',
                key: 'date',
                render: (value: string, row: any, index: number) => {
                    const ps = prices.filter((x: any) => x.roomId === row._id);
                    return ps && ps.length > 0 ? moment(ps[0].toDate).format('DD/MM/YYYY') : '---';
                }
            },
            {
                title: 'Kích hoạt',
                key: 'active',
                render: (value: string, row: any, index: number) => {
                    return <Status status={row.status}/>
                }
            },
        ];
        return <div key={`${group._id}-child`}>
            <Table pagination={false} columns={cols} rowKey={(r: any) => r._id}
                   dataSource={group.rooms}/>
        </div>
    }

    return (
        <>
            <Content>
                <Breadcrumb style={{marginBottom: 10}}>
                    <Breadcrumb.Item>Khách sạn</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/hotel/list'}>{hotel ? hotel.name.vi : ''}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="">Quản lý giá phòng</a>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Table bordered columns={columns} rowKey={(row) => row._id} dataSource={priceGroups} expandable={{
                    expandedRowRender: renderPrice,
                    defaultExpandAllRows: true,
                }}/>
            </Content>

        </>
    )
}
