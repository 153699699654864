import {Upload, Modal, message} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from "react";
import Environment from "../../environment";
import HttpService from "../../services/http.service";
import ImageService from "../../services/image.service";

function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default function UploadImages(props: any) {
    const images = props.value;
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [fileList, setFileList] = useState(images);

    useEffect(() => {
        setFileList(images);
    }, [images])

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    };

    const handleChange = (fl: any) => {
        setFileList(fl.fileList);
        props.onChange(fl.fileList.map((x: any) => x.response ? x.response : x));
    };

    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );

    const beforeUpload = (file: any) => {
        if ((file.type !== 'image/png') && (file.type !== 'image/jpeg')) {
            message.error(`${file.name} không phải là ảnh png/jpeg!`);
        }
        return (file.type === 'image/png') || (file.type === 'image/jpeg') ? true : Upload.LIST_IGNORE;
    }

    const onUploadImage = ({onSuccess, onError, file}: any) => {
        console.log(file);
        ImageService.upload(file).then(value => {
            if (value) {
                onSuccess(value)
            } else {
                onError({});
            }
        })
    }

    return (
        <>
            <Upload customRequest={options => onUploadImage(options)} beforeUpload={beforeUpload} name={'image'}
                    listType="picture-card"
                    fileList={fileList} onPreview={handlePreview} onChange={handleChange}>
                {uploadButton}
            </Upload>
            <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </>
    );

}

