import HttpService from "./http.service";
import Environment from "../environment";

export default class PriceService {
    static find(query: any) {
        return HttpService.post(Environment.host.concat('/price/find'), query);
    }

    static list(hotelId: string, page = 0, filters: any = {}) {
        return HttpService.get(Environment.host.concat(`/price/${hotelId}`), {page, filters});
    }

    static add(p: any) {
        return HttpService.post(Environment.host.concat('/price'), p);
    }

    static bulk(actions: Array<any>) {
        return HttpService.post(Environment.host.concat('/price/bulk'), actions);
    }

    static createAuto(p: any) {
        return HttpService.post(Environment.host.concat('/price/creat-price-auto'), p);
    }

    static update(_id: string, price: any) {
        return HttpService.put(Environment.host.concat(`/price/${_id}`), price);
    }

    static remove(_id: string) {
        return HttpService.delete(Environment.host.concat(`/price/${_id}`));
    }
}
