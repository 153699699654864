import React, {useEffect, useState} from "react";
import PropertyPage from "../index";
import {Checkbox, Form, Input, Select, Upload} from "antd";
import {InboxOutlined} from '@ant-design/icons';
import UploadFileContent from "../../../components/UploadFileContent";
import PropertyService from "../../../services/property.service";


export default function AmenityPage(props: any) {
    const [categories, setCategories]: Array<any> = useState([]);

    useEffect(() => {
        PropertyService.list('amenity-category').then(value => {
            if (value) {
                const v = value.map((x: any) => {
                    return {
                        name: x.value.name.vi,
                        _id: x._id
                    }
                });
                setCategories(v);
            }
        })
    }, [])


    const columns = [
        {
            title: 'Icon',
            dataIndex: 'icon',
            key: 'icon',
            render: (value: any, item: any, index: number) => <img style={{width: 28, height: 'auto'}}
                                                                   src={item.value.icon}/>,
        },
        {
            title: 'Danh mục',
            dataIndex: ['value', 'amenityCategoryId'],
            key: 'value',
            render: (cell: any, row: any) => (categories.find((x: any) => x._id === cell)?.name || "")
        },
        {
            title: 'Tên tiếng việt',
            dataIndex: ['value', 'name', 'vi'],
            key: 'value',
        },
        {
            title: 'Tên tiếng anh',
            dataIndex: ['value', 'name', 'en'],
            key: 'value',
        }, {
            title: 'Mặc định',
            dataIndex: ['value', 'isDefault'],
            key: 'isDefault',
            render: (cell: boolean, row: any) => {
                console.log(cell);
                return <Checkbox checked={cell}/>
            }
        },
    ]

    return (
        <>
            <PropertyPage title='Quản lý tiện nghi'
                          desc="Khách sạn"
                          type='amenity'
                          columns={columns}
            >
                <Form.Item name={['amenityCategoryId']} label="Danh mục">
                    <Select>
                        {
                            categories.map((value: any, index: number) => {
                                return (
                                    <Select.Option key={index.toString()} value={value._id}>{value.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name={['name', 'vi']} label="Tên tiếng việt">
                    <Input placeholder="Nhập tên tiếng việt"/>
                </Form.Item>
                <Form.Item name={['name', 'en']} label="Tên tiếng anh">
                    <Input placeholder="Nhập tên tiếng việt"/>
                </Form.Item>
                <Form.Item name={['isDefault']} valuePropName="checked">
                    <Checkbox>Chọn mặc định</Checkbox>
                </Form.Item>
                <Form.Item label="Chọn icon">
                    <Form.Item name="icon">
                        <UploadFileContent/>
                    </Form.Item>
                </Form.Item>
            </PropertyPage>
        </>
    )
}
