import React from "react";
import styled from "styled-components";

const PanelSearch = styled.ul`
  background-color: white;
  padding: 5px 12px;
  border-radius: 0px;
  margin-bottom: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .ant-row.ant-form-item:last-child {
    margin-right: 0;
  }
`;

export default PanelSearch;