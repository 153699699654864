import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Form, Input, Popconfirm, Select, Table, Tooltip} from "antd";
import Content from "../../../components/Containers/Content";
import Panel from "../../../components/Containers/Panel";
import {Link, useHistory, useParams} from "react-router-dom";
import RoomService from "../../../services/room.service";
import PanelActions from "../../../components/Containers/PanelActions";
import PanelAction from "../../../components/Elements/PanelAction";
import PanelSearch from "../../../components/Containers/PanelSearch";

export default function ListRoomPage(props: any) {
    const {hotelId}: any = useParams();
    const history = useHistory();
    const [data, setData]: Array<any> = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        onListRoom();
    }, []);

    const onGoPrice = () => {
        history.push(`/price/${hotelId}`);
    }

    const columns = [
        {
            title: 'Hình', key: 'index',
            render: (value: string, row: any, index: number) => {
                return (
                    <div className={'col-images'}>
                        <img src={row.images[0]?.url}/>
                        <a>+{row.images.length}</a>
                    </div>
                )
            }
        },
        {
            title: 'Tên khách sạn', key: 'name', dataIndex: ['name', 'vi'],
            render: (value: string, row: any, index: number) => {
                return (

                    <div className={'col-name one-line'}>
                        <a onClick={() => onDetail(row._id)}>
                            <span className={`room-name ${value.indexOf('<b>') < 0 ? 'not-name' : ''}`}
                                  dangerouslySetInnerHTML={{__html: value}}/>
                        </a>
                        <span>Có giá tới 50 ngày sau</span>
                    </div>
                )
            }
        },
        {
            title: 'Thứ tự', key: 'price',
            render: (value: string, row: any, index: number) => {
                return (
                    <a>0</a>
                )
            }
        },
        {
            title: 'Mức giá', key: 'features',
            render: (value: string, row: any, index: number) => {
                return (
                    <a onClick={() => onGoPrice()}>{row.prices?.length || 0}</a>
                )
            }
        },
        {title: 'Quyền lợi', key: 'note'},
        {
            title: 'Gường thêm', key: 'status',
            render: (value: string, row: any, index: number) => {
                return row.status === 'active' ? (
                    <i className="fas fa-check"/>
                ) : null
            }
        },
        {
            title: 'Books', key: 'comments',
            render: (value: string, row: any, index: number) => {
                return 0
            }
        },
        {
            title: 'Phụ thu', key: 'books',
            render: (value: string, row: any, index: number) => {
                return 0
            }
        },
        {
            title: 'Hết giá', key: 'priceEx',
            render: (value: string, row: any, index: number) => {
                return (
                    <div>
                        <span>20/12/2021</span>
                    </div>
                )
            }
        },
        {
            title: 'Phòng gốc', key: 'contract',
            render: (value: string, row: any, index: number) => {
                return (
                    <div>
                        <span>20/12/2021</span>
                    </div>
                )
            }
        },
    ]

    const onListRoom = async () => {
        setLoading(true);
        const value = await RoomService.list(hotelId);
        if (value) {
            setData(value);
        }
        setLoading(false);
    }

    const onGoAdd = () => {
        history.push(`/room/add/${hotelId}`);
    }

    const onDetail = (roomId: string) => {
        history.push(`/room/update/${roomId}`);
    }

    const onRemove = async (roomId: string) => {
        await RoomService.remove(roomId).then()
        onListRoom();
    }

    const onSearch = (values: any) => {

    }

    return (
        <>
            <Content>
                <Breadcrumb style={{marginBottom: 10}}>
                    <Breadcrumb.Item>Khách sạn</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/hotel/list'}>Danh sách khách sạn</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="">Danh sách phòng</a>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <PanelActions>
                    <PanelAction onClick={() => onGoAdd()} title={'Thêm'} icon={<i className="fas fa-plus"/>}/>
                    <PanelAction title={'Kích hoạt'} icon={<i className="fas fa-check"/>}/>
                    <PanelAction title={'Ngừng'} icon={<i className="fas fa-exclamation-triangle"/>}/>
                </PanelActions>

                <PanelSearch>
                    <Form layout={'inline'} onFinish={onSearch}>
                        <Form.Item name={['price']}>
                            <Select allowClear size={'small'} placeholder={'Tình trạng giá'}>
                                <Select.Option value="none">Chưa có giá</Select.Option>
                                <Select.Option value="comingEnd">Sắp hết giá</Select.Option>
                                <Select.Option value="normal">Còn giá</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name={['status']}>
                            <Select allowClear size={'small'} placeholder={'Chọn trạng thái'}>
                                <Select.Option value="active">Còn làm việc</Select.Option>
                                <Select.Option value="inactive">Hết làm việc</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name={['name']}>
                            <Input placeholder={'Tên khách sạn'} size={"small"}/>
                        </Form.Item>

                        <Form.Item>
                            <Button size={"small"} htmlType={'submit'} type="primary">Tìm kiếm</Button>
                        </Form.Item>
                    </Form>
                </PanelSearch>
                <Table bordered rowKey={(row: any) => row._id.toString()} loading={loading} dataSource={data} columns={columns}
                       rowSelection={{
                           type: 'checkbox',
                       }}/>
            </Content>
        </>
    )
}
