import React from "react";
import PropertyPage from "../index";
import {Form, Input} from "antd";
import UploadFileContent from "../../../components/UploadFileContent";


export default function CountryPage(props: any) {
    const columns = [
        {
            title: 'Hình ảnh',
            dataIndex: 'image',
            key: 'image',
            render: (value: any, item: any, index: number) => <img style={{width: 50, height: 'auto'}}
                                                                   src={item.value.image}/>,
        },
        {
            title: 'Tên quốc gia',
            dataIndex: ['value', 'name'],
            key: 'value',
        },
    ];

    return (
        <>
            <PropertyPage title='Quản lý quốc gia' desc="Khách sạn" type='country' columns={columns}>
                <Form.Item name={['name']} label="Tên quốc gia">
                    <Input placeholder="Nhập tên quốc gia"/>
                </Form.Item>
                <Form.Item label="Chọn icon">
                    <Form.Item name="image">
                        <UploadFileContent/>
                    </Form.Item>
                </Form.Item>
            </PropertyPage>
        </>
    )
}
