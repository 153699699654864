import React, {useEffect, useState} from "react";
import {InboxOutlined} from "@ant-design/icons";
import {Upload} from "antd";
import Environment from "../../environment";
import HttpService from "../../services/http.service";

export default function UploadFileContent(props: any) {
    const [value, setValue] = useState(props.defaultValue);

    useEffect(() => {
        props.onChange(value);
    }, [value]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleChange = (fl: any) => {
        if (fl.file.status === 'done') {
            setValue(fl.file.response.url);
        }
    };

    return (
        <Upload.Dragger headers={HttpService.getHeaders()} name={'image'} onChange={handleChange} multiple={false}
                        action={`${Environment.hostImage}`}
                        beforeUpload={(file, FileList) => {
                            const reader = new FileReader();
                            if (file.type.toString().indexOf('image') < 0) {
                                alert('Hình ảnh không hợp lệ!');
                                return false;
                            }
                            return true;
                        }}>
            <p className="ant-upload-drag-icon">
                {!value ? <InboxOutlined/> : <img style={{width: 40, height: 'auto'}} src={value}/>}
            </p>
            <p className="ant-upload-text">Chọn hình ảnh để tải lên</p>
            <p className="ant-upload-hint">Kéo thả hình ảnh vào đây</p>
        </Upload.Dragger>
    )
}
