import {DatePicker} from "antd";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {Form} from "antd";

export default function DateRangePicker(props: any) {
    const form = props.form;
    const fields: any = props.fields;
    const valueProp: any = props.value;
    const onChange: any = props.onChange;
    const [value, setValue]: any = useState([moment(), moment().add(30, 'days')]);


    useEffect(() => {
        if (form) {
            const values = form.getFieldsValue();
            const data: any = [];
            data.push(values[fields[0]]);
            data.push(values[fields[1]]);
            console.log(values[fields[1]]);
            setValue(data);
        }
    }, [form, valueProp]);

    return <div className={`ant-row ant-form-item ${props.layout ? 'date-picker-layout' : ''}`}>
        <div className={'ant-col ant-form-item-label'}>
            <label>{props.label || ''}</label>
        </div>
        <Form.Item hidden={true} name={fields[0]}>
            <DatePicker/>
        </Form.Item>
        <Form.Item hidden={true} name={fields[1]}>
            <DatePicker/>
        </Form.Item>
        <DatePicker.RangePicker
            format={'DD/MM/YYYY'} value={value}
            onChange={(values: any) => {
                const data: any = {};
                data[fields[0]] = values[0];
                data[fields[1]] = values[1];
                form.setFieldsValue(data);
                setValue(values);
                if (onChange) {
                    onChange(values)
                }
            }}/>
    </div>
}
