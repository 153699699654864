import {applyMiddleware, createStore} from "redux";
import rootReducer from './reducer.redux';
import thunk from 'redux-thunk'

const middleware = [thunk];

const persistedState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState') || "") : {};

const store = createStore(rootReducer, persistedState, applyMiddleware(...middleware));

store.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()))
});

export default store;
