import React from "react";
import styled from "styled-components";

const PanelTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;

export default PanelTitle;
