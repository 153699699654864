import React from "react";
import styled from "styled-components";

const Panel = styled.div`
  background-color: white;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 20px;
`;

export default Panel;
