import React, {useEffect, useState} from "react";
import PropertyPage from "../index";
import {Form, Input, Select} from "antd";
import UploadFileContent from "../../../components/UploadFileContent";
import PropertyService from "../../../services/property.service";

const {Option} = Select;

export default function CityPage(props: any) {
    const [countries, setCountries]: any = useState([]);

    useEffect(() => {
        PropertyService.list('country').then(value => {
            if (value) {
                const v = value.map((x: any) => {
                    return {
                        name: x.value.name,
                        _id: x._id
                    }
                });
                console.log(v);
                setCountries(v);
            }
        })
    }, [])

    const columns = [
        {
            title: 'Hình ảnh',
            dataIndex: 'image',
            key: 'image',
            render: (value: any, item: any, index: number) => <img style={{width: 50, height: 'auto'}}
                                                                   src={item.value.image}/>,
        },{
            title: 'Tên tỉnh thành',
            dataIndex: ['value', 'name'],
            key: 'value',
        },
        {
            title: 'Quốc gia',
            dataIndex: ['value', 'countryId'],
            key: 'value',
            render: (cell: any, row: any) => (countries.find((x: any) => x._id === cell)?.name || "")
        },

    ];

    return (
        <>
            <PropertyPage title='Quản lý danh sách tỉnh thành' desc="Khách sạn" type='city' columns={columns}>
                <Form.Item name={['countryId']} label="Quốc gia">
                    <Select>
                        {
                            countries.map((value: any, index: number) => {
                                return (
                                    <Option key={index.toString()} value={value._id}>{value.name}</Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name={['name']} label="Tên tỉnh thành">
                    <Input placeholder="Nhập tên tỉnh thành"/>
                </Form.Item>
                <Form.Item label="Chọn icon">
                    <Form.Item name="image">
                        <UploadFileContent/>
                    </Form.Item>
                </Form.Item>
            </PropertyPage>
        </>
    )
}
