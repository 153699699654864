import HttpService from "./http.service";
import Environment from "../environment";

export default class ImageService {

    static upload(file: any) {
        var formdata = new FormData();
        formdata.append("image", file);
        return HttpService.post(Environment.hostImage, formdata, {
            "Content-Type": "multipart/form-data",
        });
    }
}
