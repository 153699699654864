import React from "react";
import styled from "styled-components";

const PanelActions = styled.ul`
  background-color: white;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 10px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export default PanelActions;