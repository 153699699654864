import React from "react";
import Navigation from "../components/Navigation";
import AppRoute from "../routers";
import {BrowserRouter as Router} from "react-router-dom";
import {Layout} from 'antd';
import {useSelector} from "react-redux";
import AuthPage from "./Auth";

const {Content, Footer, Sider} = Layout;

export default function AppPage(props: any) {
    const user = useSelector((state: any) => state.Auth.user);
    console.log(user);
    return !!user ? (
        <Router>
            <Navigation/>
            <Layout style={{minHeight: '100%'}}>
                <Layout>
                    <Content>
                        <AppRoute/>
                    </Content>
                    <Footer style={{textAlign: 'center'}}>Trippyasia System ©2021</Footer>
                </Layout>
            </Layout>
        </Router>
    ) : (
        <AuthPage/>
    )
}
