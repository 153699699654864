const Environment = !!process.env.REACT_APP_PRODUCTION ? {
    API_KEY: '701f9aa39cdd506d777586116df04c29',
    hostImage: 'https://server.trippyasia.com/manager/api/image/upload',
    host: 'https://server.trippyasia.com/manager/api',
} : {
    API_KEY: '701f9aa39cdd506d777586116df04c29',
    hostImage: 'https://server.trippyasia.com/manager/api/image/upload',
    host: 'http://localhost:3001/manager/api',
};
export default Environment;
