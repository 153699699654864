import React from "react";
import {InputNumber} from "antd";


export default function PriceInput(props: any) {
    return <InputNumber
        {...props}
        style={{width: '100%'}}
        formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
    />
}