import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Form, Input, PageHeader, Popconfirm, Select, Table, Tooltip} from "antd";
import Content from "../../components/Containers/Content";
import {useHistory} from "react-router-dom";
import HotelService from "../../services/hotel.service";
import PanelActions from "../../components/Containers/PanelActions";
import PanelAction from "../../components/Elements/PanelAction";
import PanelSearch from "../../components/Containers/PanelSearch";
import PropertyService from "../../services/property.service";
import {convertPropertyToIdName, getIdByName} from "../../services/app.service";
import Status from "../../components/Status";

export default function ListHotelPage(props: any) {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [countries, setCountries]: any = useState([]);
    const [cities, setCities]: any = useState([]);
    const [places, setPlaces]: any = useState([]);
    const [features, setFeatures]: any = useState([]);
    const [filters, setFilters]: any = useState({});

    const columns = [
        {
            title: 'Hình', key: 'index',
            render: (value: string, row: any, index: number) => {
                return (
                    <div className={'col-images'}>
                        <img src={row.images[0]?.url}/>
                        <a>+{row.images.length}</a>
                    </div>
                )
            }
        },
        {
            title: 'Tên khách sạn', key: 'name', dataIndex: ['name', 'vi'],
            render: (value: string, row: any, index: number) => {
                return (
                    <div className={'col-name'}>
                        <a><b onClick={() => onDetail(row._id)}>{value}</b></a>
                        <span>Có giá tới 50 ngày sau</span>
                    </div>
                )
            }
        },
        {title: 'Điện thoại', key: 'phone', dataIndex: ['contact', 'phone']},
        {
            title: 'Loại phòng', key: 'rooms',
            render: (value: string, row: any, index: number) => {
                return (
                    <a onClick={() => onRoomList(row._id)}>{row.rooms.length}</a>
                )
            }
        },
        {
            title: 'Nhóm giá', key: 'price',
            render: (value: string, row: any, index: number) => {
                return (
                    <a onClick={() => onGoPriceGroup(row._id)}>{row.priceGroups?.length || 0}</a>
                )
            }
        },
        {
            title: 'Bảng giá', key: 'price',
            render: (value: string, row: any, index: number) => {
                return (
                    <a onClick={() => onGoPrice(row._id)}>{row.prices?.length || 0}</a>
                )
            }
        },
        {
            title: 'Tiện nghi', key: 'amenities',
            render: (value: string, row: any, index: number) => {
                return (
                    <a>{row.amenities?.length || 0}</a>
                )
            }
        },
        {title: 'Note', key: 'note'},
        {
            title: 'Trạng thái', key: 'status', dataIndex: 'status',
            render: (value: string, row: any, index: number) => {
                return <Status status={value}/>
            }
        },
        {
            title: 'Comments', key: 'comments',
            render: (value: string, row: any, index: number) => {
                return 0
            }
        },
        {
            title: 'Books', key: 'books',
            render: (value: string, row: any, index: number) => {
                return 0
            }
        },
        {
            title: 'Hết giá', key: 'priceEx',
            render: (value: string, row: any, index: number) => {
                return (
                    <div>
                        <span>20/12/2021</span>
                    </div>
                )
            }
        },
        {
            title: 'Hợp đồng', key: 'contract',
            render: (value: string, row: any, index: number) => {
                return (
                    <div>
                        <span>20/12/2021</span>
                    </div>
                )
            }
        },
    ]

    useEffect(() => {
        getInit().then();
    }, []);

    useEffect(() => {
        onListHotel();
    }, [filters])

    const getInit = async () => {
        let result: any = await PropertyService.listByGroup(['country', 'city', 'place', 'feature']).catch(reason => []);
        setCountries(convertPropertyToIdName(result['country']));
        setCities(convertPropertyToIdName(result['city']));
        setPlaces(convertPropertyToIdName(result['place']));
        setFeatures(convertPropertyToIdName(result['feature']));
    }

    const onSearch = (values: any) => {
        setFilters(values);
    }

    const onListHotel = () => {
        setLoading(true);
        HotelService.list(filters).then(value => {
            setData(value);
            setLoading(false);
        });
    }

    const onGoAdd = () => {
        history.push('/hotel/add');
    }

    const onGoPrice = (hotelId: string) => {
        history.push(`/price/${hotelId}`);
    }
    const onGoPriceGroup = (hotelId: string) => {
        history.push(`/price-group/${hotelId}`);
    }


    const onAddFromAgoda = async () => {
        setLoading(true);
        const url = prompt('Nhập link agoda:');
        if (url) {
            const result = await HotelService.crawlAgoda(url);
            if (result) {
                // Remap address
                const countryId = getIdByName(countries, result.location.country);
                const cityId = getIdByName(cities, result.location.city);
                const placeId = getIdByName(places, result.location.place);
                if (!countryId) {
                    alert(`Chưa có quốc gia ${result.location.country}`);
                    setLoading(false);
                    return;
                }
                if (!cityId) {
                    alert(`Chưa có thành phố ${result.location.city}`);
                    setLoading(false);
                    return;
                }
                if (!placeId) {
                    alert(`Chưa có địa điểm ${result.location.place}`);
                    setLoading(false);
                    return;
                }
                result.location.country = countryId;
                result.location.city = cityId;
                result.location.place = placeId;
                await HotelService.add(result);
                onListHotel();
            } else {
                alert(`Không tìm thấy dữ liệu!`);
            }
        }
        setLoading(false);
    }

    const onDetail = (hotelId: string) => {
        history.push(`/hotel/update/${hotelId}`);
    }

    const onRoomList = (hotelId: string) => {
        history.push(`/room/list/${hotelId}`);
    }

    const onRemove = async (hotelId: string) => {
        await HotelService.remove(hotelId).then();
        onListHotel();
    }

    return (
        <>
            <Content>
                <Breadcrumb style={{marginBottom: 10}}>
                    <Breadcrumb.Item>Khách sạn</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="">Danh sách khách sạn</a>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <PanelActions>
                    <PanelAction onClick={() => onGoAdd()} title={'Thêm'} icon={<i className="fas fa-plus"/>}/>
                    <PanelAction onClick={() => onAddFromAgoda()} title={'Thêm từ Agoda'}
                                 icon={<i className="fas fa-plus"/>}/>
                    <PanelAction title={'Kích hoạt'} icon={<i className="fas fa-check"/>}/>
                    <PanelAction title={'Ngừng'} icon={<i className="fas fa-exclamation-triangle"/>}/>
                </PanelActions>

                <PanelSearch>
                    <Form layout={'inline'} onFinish={onSearch}>
                        <Form.Item name={['price']}>
                            <Select allowClear size={'small'} placeholder={'Tình trạng giá'}>
                                <Select.Option value="none">Chưa có giá</Select.Option>
                                <Select.Option value="comingEnd">Sắp hết giá</Select.Option>
                                <Select.Option value="normal">Còn giá</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name={['status']}>
                            <Select allowClear size={'small'} placeholder={'Chọn trạng thái'}>
                                <Select.Option value="active">Còn làm việc</Select.Option>
                                <Select.Option value="inactive">Hết làm việc</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name={['country']}>
                            <Select allowClear size={'small'} placeholder={'Quốc gia'}>
                                {
                                    countries.map((value: any, index: number) => (
                                        <Select.Option key={index.toString()}
                                                       value={value._id}>{value.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name={['city']}>
                            <Select allowClear size={'small'} placeholder={'Tỉnh thành'}>
                                {
                                    cities.map((value: any, index: number) => (
                                        <Select.Option key={index.toString()}
                                                       value={value._id}>{value.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name={['name']}>
                            <Input placeholder={'Tên khách sạn'} size={"small"}/>
                        </Form.Item>

                        <Form.Item>
                            <Button size={"small"} htmlType={'submit'} type="primary">Tìm kiếm</Button>
                        </Form.Item>
                    </Form>
                </PanelSearch>
                <Table bordered rowKey={(row: any) => row._id.toString()} loading={loading} dataSource={data}
                       columns={columns}
                       rowSelection={{
                           type: 'checkbox',
                       }}/>
            </Content>
        </>
    )
}
