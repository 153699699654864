import React from 'react';
import './App.css';
import {Provider} from "react-redux";
import storeRedux from "./redux/store.redux";
import 'antd/dist/antd.css';
import AppPage from './pages';

function App() {


    return (
        <div className="app">
            <Provider store={storeRedux}>
                <AppPage/>
            </Provider>
        </div>

    );
}

export default App;
