import HttpService from "./http.service";
import Environment from "../environment";
import moment from "moment";

export function login(username: string, password: string) {
    return HttpService.post(Environment.host.concat(`/auth/login`), {username, password})
}

export function getIdByName(arr: any, name: string) {
    console.log(arr, name);
    const item = arr.find((x: any) => x.name === name);
    return item ? item._id : null;
}

// CHuyển property sang dạng id name
export function convertPropertyToIdName(arr: Array<any>, addNamePath: string = '', customType: any = null) {
    return arr ? arr.map((x: any) => {
        const result: any = {
            name: !addNamePath ? x.value.name : x.value.name[addNamePath],
            _id: x._id,
        }
        if (customType) {
            const keys = Object.keys(customType);
            keys.map((value, index) => {
                result[value] = x.value[customType[value]];
            })
        }
        return result;
    }) : [];
}

// Chuyển nhóm giá sang object data
export function convertPriceGroupToObject(priceGroup: any) {
    const obj = {...priceGroup.value};
    if (priceGroup.value && priceGroup.roomName) {
        obj.name = priceGroup.roomName || '';
    }
    if (priceGroup.fromDate) {
        obj.fromDate = moment(priceGroup.fromDate).format('DD/MM/YYYY');
    }
    if (priceGroup.toDate) {
        obj.toDate = moment(priceGroup.toDate).format('DD/MM/YYYY');
    }
    if (priceGroup.discount) {
        obj.discountValue = priceGroup.discount.value;
        obj.discountUnit = priceGroup.discount.unit === 'percent' ? '%' : 'đ';
    }
    if (priceGroup.value && priceGroup.value.rangeDateCreated && priceGroup.value.rangeDateCreated[0] && priceGroup.value.rangeDateCreated[1]) {
        obj.rangeDateCreated = moment(priceGroup.value.rangeDateCreated[0]).diff(moment(priceGroup.value.rangeDateCreated[1]), 'days') + 1;
    }
    if (priceGroup.value && priceGroup.value.rangeDateStay && priceGroup.value.rangeDateStay[0] && priceGroup.value.rangeDateStay[1]) {
        obj.rangeDateStay = moment(priceGroup.value.rangeDateStay[0]).diff(moment(priceGroup.value.rangeDateStay[1]), 'days') + 1;
    }
    return obj;
}

// Apply text nhóm giá
export function applyTemplate(template: string, obj: any, outputFormat: string = 'text') {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (outputFormat === 'text') {
            template = template.split(`{${key}}`).join(obj[key]);
        } else {
            template = template.split(`{${key}}`).join(`<span class="template-replaced ${key}">${obj[key]}</span>`);
        }
    }
    return template;
}
