import HttpService from "./http.service";
import Environment from "../environment";

export default class RoomService {
    static find(query: any) {
        return HttpService.post(Environment.host.concat('/room/find'), query);
    }

    static list(hotelId: string) {
        return HttpService.get(Environment.host.concat(`/room/list/${hotelId}`));
    }

    static get(roomId: string) {
        return HttpService.get(Environment.host.concat(`/room/${roomId}`));
    }

    static add(p: any) {
        return HttpService.post(Environment.host.concat('/room'), p);
    }

    static update(_id: string, room: any) {
        return HttpService.put(Environment.host.concat(`/room/${_id}`), room);
    }

    static remove(_id: string) {
        return HttpService.delete(Environment.host.concat(`/room/${_id}`));
    }
}
