import {DatePicker, TimePicker} from "antd";
import React, {useEffect, useState} from "react";
import moment from "moment";

export default function DatePickerForm(props: any) {
    const [value, setValue]: any = useState(moment());

    useEffect(() => {
        setValue(moment(props.value));
    }, [props.value])

    return <DatePicker style={{width: '100%'}} value={value} defaultValue={value} onChange={(value1, dateString) => {
        props.onChange(value1?.toDate());
    }} format="DD/MM/YYYY"/>
}
