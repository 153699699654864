import React from "react";
// @ts-ignore
import {CKEditor} from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function MceEditor(props: any) {
    return <div style={{width: '50%', ...(props.style || {})}}>
        <CKEditor editor={ClassicEditor} data={props.value}
                  onChange={(event: any, editor: any) => props.onChange(editor.getData())}/>
    </div>
}