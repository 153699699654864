import React, {useEffect, useState} from "react";
import {PageHeader, Button, Table, Modal, Form, Space, Breadcrumb} from 'antd';
import Content from "../../components/Containers/Content";
import {DeleteFilled} from '@ant-design/icons';
import IconPicker from "../../components/IconPicker";
import {Input} from 'antd';
import "./style.scss";
import PropertyService from "../../services/property.service";
import Panel from "../../components/Containers/Panel";
import PanelActions from "../../components/Containers/PanelActions";
import PanelAction from "../../components/Elements/PanelAction";
import {FormLayout} from "../../constants/formLayout";


export default function PropertyPage(props: any) {
    const [data, setData]: any = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [indexSelected, setIndexSelected] = useState(-1);
    const [actionType, setActionType] = useState('ADD');
    const [form] = Form.useForm();

    const type = props.type;
    const head = {
        title: props.title,
        desc: props.desc
    }

    const columns = [
        ...(!!props.columns ? props.columns : []),
        {
            title: 'Hành động',
            dataIndex: '_id',
            key: '_id',
            render: (value: any, item: any, index: number) => <Space size="middle">
                <Button onClick={event => openModalUpdate(index)} type="primary" size="small" ghost>Sửa</Button>
                <Button onClick={event => handleRemove(index)} size="small" danger>Xóa</Button>
            </Space>
        }
    ]

    useEffect(() => {
        onList();
    }, []);

    const onList = () => {
        PropertyService.list(type).then(value => {
            if (value) {
                setData(value);
            }
        })
    }

    const handleUpdate = async (index: number, value: any) => {
        const newItem: any = [...data];
        newItem[index].value = value;
        setData(newItem);
        await PropertyService.update(newItem[index]._id, newItem[index]);
    }

    const handleAdd = async (value: any) => {
        setIsModalVisible(true);
        let item: any = {
            type: type,
            value: value
        };
        const result = await PropertyService.add(item);
        if (result) {
            item = result;
            const newItem = [...data];
            newItem.push(item);
            setData(newItem);
        }
    }

    const handleRemove = async (index: any) => {
        let newItem = [...data];
        await PropertyService.remove(newItem[index]._id);
        newItem = newItem.filter((x, i) => i !== index);
        setData(newItem);
    }

    const handleOk = async () => {
        if (actionType === "ADD") {
            await handleAdd(form.getFieldsValue()).then();
        }
        if (actionType === "UPDATE") {
            await handleUpdate(indexSelected, form.getFieldsValue()).then();
        }
        setIsModalVisible(false);
        onList();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const openModalAdd = () => {
        setIsModalVisible(true);
        setActionType('ADD');
        form.resetFields();
    }

    const openModalUpdate = (index: any) => {
        setIsModalVisible(true);
        setIndexSelected(index);
        setActionType('UPDATE');
        form.resetFields();
        form.setFieldsValue(data[index] ? data[index].value : {});
    }
    return (
        <>
            <Content>
                <Breadcrumb style={{marginBottom: 10}}>
                    <Breadcrumb.Item>Khách sạn</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="">Danh sách khách sạn</a>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <PanelActions>
                    <PanelAction onClick={() => openModalAdd()} title={'Thêm'} icon={<i className="fas fa-plus"/>}/>
                </PanelActions>

                <Table bordered rowKey={(item: any) => item._id.toString()} columns={columns} dataSource={data}/>

                <Form labelCol={{span: 6}} wrapperCol={{span: 18}} form={form} layout="vertical"
                      className='form-vertical'>
                    <Modal width={props.modalWidth ? props.modalWidth : 600}
                           title={actionType === 'ADD' ? `Tạo mới dữ liệu` : `Cập nhật dữ liệu`}
                           visible={isModalVisible}
                           onOk={handleOk}
                           onCancel={handleCancel}>
                        {props.children}
                    </Modal>
                </Form>
            </Content>
        </>
    )
}
