import HttpService from "./http.service";
import Environment from "../environment";

export default class HotelService {
    static get(_id: string) {
        return HttpService.get(Environment.host.concat(`/hotel/${_id}`));
    }

    static list(filters: any) {
        return HttpService.get(Environment.host.concat(`/hotel`), filters);
    }

    static add(p: { type: string, value: any }) {
        return HttpService.post(Environment.host.concat('/hotel'), p);
    }

    static update(_id: string, hotel: any) {
        return HttpService.put(Environment.host.concat(`/hotel/${_id}`), hotel);
    }

    static remove(_id: string) {
        return HttpService.delete(Environment.host.concat(`/hotel/${_id}`));
    }

    static crawlAgoda(url: string) {
        return HttpService.post(Environment.host.concat('/hotel/crawl/agoda'), {url});
    }
}
