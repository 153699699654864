import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Form,
    Input,
    PageHeader,
    Rate,
    Select,
    Space,
    Table,
    TimePicker,
    Checkbox,
    message,
    FormInstance, Breadcrumb
} from "antd";
import Content from "../../components/Containers/Content";
import Panel from "../../components/Containers/Panel";
import PanelTitle from "../../components/Elements/PanelTitle";
import "./style.scss";
import PropertyService from "../../services/property.service";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import UploadImages from "../../components/UploadImages";
import TimePickerForm from "../../components/TimePicker";
import HotelService from "../../services/hotel.service";
import {Link, useHistory, useParams} from "react-router-dom";
import MceEditor from "../../components/MceEditor";
import {convertPropertyToIdName} from "../../services/app.service";

const {TextArea} = Input;


export default function AddHotelPage(props: any) {
    const editorRef: any = useRef(null);
    const [countries, setCountries]: any = useState([]);
    const [cities, setCities]: any = useState([]);
    const [places, setPlaces]: any = useState([]);
    const [features, setFeatures]: any = useState([]);
    const [amenities, setAmenities]: any = useState([]);
    const [amenityCategories, setAmenityCategories]: any = useState([]);
    const history = useHistory();
    const {hotelId}: any = useParams();
    const [hotel, setHotel]: any = useState();
    const [form] = Form.useForm();

    useEffect(() => {
        if (hotelId) {
            HotelService.get(hotelId).then(value => {
                if (value) {
                    setHotel(value);
                    form.setFieldsValue(value);
                }
            })
        }
    }, [hotelId])

    const onSubmit = (values: any) => {
        if (!hotelId) {
            HotelService.add(values).then(value => {
                if (value) {
                    message.success('Thêm thành công!');
                    history.goBack();
                }
            });
        } else {
            HotelService.update(hotelId, {...hotel, ...values}).then(value => {
                if (value) {
                    message.success('Cập nhật thành công!');
                    history.goBack();
                }
            });
        }
    }

    useEffect(() => {
        getInit().then()
    }, [])

    const getInit = async () => {
        let result: any = await PropertyService.listByGroup(['country', 'city', 'place', 'feature', 'amenity', 'amenity-category']).catch(reason => []);
        setCountries(convertPropertyToIdName(result['country']));
        setCities(convertPropertyToIdName(result['city']));
        setPlaces(convertPropertyToIdName(result['place']));
        setFeatures(convertPropertyToIdName(result['feature'], 'vi'));
        setAmenities(convertPropertyToIdName(result['amenity'], 'vi', {amenityCategoryId: 'amenityCategoryId'}));
        setAmenityCategories(convertPropertyToIdName(result['amenity-category'], 'vi'));
    }

    return (
        <>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Content>
                    <Breadcrumb style={{marginBottom: 10}}>
                        <Breadcrumb.Item>Khách sạn</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/hotel/list">Danh sách khách sạn</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a>Thông tin</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Panel>
                        <PanelTitle>Thông tin cơ bản</PanelTitle>

                        <Form.Item label="Tên khách sạn (vi/en)">
                            <Input.Group compact>
                                <Form.Item name={['name', 'vi']} noStyle>
                                    <Input style={{width: '50%'}} placeholder="Nhập tên khách sạn..."/>
                                </Form.Item>
                                <Form.Item name={['name', 'en']} noStyle>
                                    <Input style={{width: '50%'}} placeholder="Input hotel name..."/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        <Form.Item label="Mô tả ngắn (vi/en)">
                            <Input.Group compact>
                                <Form.Item name={['description', 'summary', 'vi']} noStyle>
                                    <MceEditor/>
                                </Form.Item>
                                <Form.Item name={['description', 'summary', 'en']} noStyle>
                                    <MceEditor/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        <Form.Item label="Mô tả đầy đủ (vi/en)">
                            <Input.Group compact>
                                <Form.Item name={['description', 'full', 'vi']} noStyle>
                                    <MceEditor/>
                                </Form.Item>
                                <Form.Item name={['description', 'full', 'en']} noStyle>
                                    <MceEditor/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        <Form.Item label="Thời gian checkin/checkout">
                            <Input.Group compact>
                                <Form.Item name={['timer', 'ci']} noStyle>
                                    <TimePickerForm/>
                                </Form.Item>
                                <Form.Item name={['timer', 'co']} noStyle>
                                    <TimePickerForm/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                        <Form.Item name="star" label="Số sao">
                            <Rate/>
                        </Form.Item>


                    </Panel>

                    <Panel>
                        <PanelTitle>Thông tin địa chỉ</PanelTitle>

                        <Form.Item name={['location', 'country']} label="Quốc gia">
                            <Select>
                                {
                                    countries.map((value: any, index: number) => (
                                        <Select.Option key={index.toString()}
                                                       value={value._id}>{value.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name={['location', 'city']} label="Tỉnh thành">
                            <Select>
                                {
                                    cities.map((value: any, index: number) => (
                                        <Select.Option key={index.toString()}
                                                       value={value._id}>{value.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name={['location', 'place']} label="Địa điểm">
                            <Select>
                                {
                                    places.map((value: any, index: number) => (
                                        <Select.Option key={index.toString()}
                                                       value={value._id}>{value.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item label="Địa chỉ (vi/en)">
                            <Input.Group compact>
                                <Form.Item name={['location', 'address', 'vi']} noStyle>
                                    <TextArea style={{width: '50%'}} rows={3}/>
                                </Form.Item>
                                <Form.Item name={['location', 'address', 'en']} noStyle>
                                    <TextArea style={{width: '50%'}} rows={3}/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                    </Panel>

                    <Panel>
                        <PanelTitle>Thông liên hệ khách sạn</PanelTitle>

                        <Form.Item label="Số điện thoại" name={['contact', 'phone']}
                                   key={['contact', 'phone'].join('')}>
                            <Input placeholder="Số điện thoại"/>
                        </Form.Item>

                        <div className="div-form">
                            <label className="lb-form">Nhân viên sale</label>
                            <Form.List name={["contact", "sales"]}>
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, fieldKey, ...restField}) => (
                                            <Space key={key} style={{display: 'flex', marginBottom: 8}}
                                                   align="baseline">
                                                <Form.Item name={[name, 'name']} fieldKey={[fieldKey, 'name']}>
                                                    <Input placeholder="Họ và tên"/>
                                                </Form.Item>
                                                <Form.Item name={[name, 'email']} fieldKey={[fieldKey, 'email']}>
                                                    <Input placeholder="Địa chỉ email"/>
                                                </Form.Item>
                                                <Form.Item name={[name, 'phone']} fieldKey={[fieldKey, 'phone']}>
                                                    <Input placeholder="Số điện thoại"/>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} style={{width: '60%'}}
                                                    icon={<PlusOutlined/>}>
                                                Thêm nhân viên sale
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>

                        <div className="div-form">
                            <label className="lb-form">Nhân viên dự phòng</label>
                            <Form.List name={["contact", "reservation"]}>
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, fieldKey, ...restField}) => (
                                            <Space key={key} style={{display: 'flex', marginBottom: 8}}
                                                   align="baseline">
                                                <Form.Item name={[name, 'name']} fieldKey={[fieldKey, 'name']}>
                                                    <Input placeholder="Họ và tên"/>
                                                </Form.Item>
                                                <Form.Item name={[name, 'email']} fieldKey={[fieldKey, 'email']}>
                                                    <Input placeholder="Địa chỉ email"/>
                                                </Form.Item>
                                                <Form.Item name={[name, 'phone']} fieldKey={[fieldKey, 'phone']}>
                                                    <Input placeholder="Số điện thoại"/>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} style={{width: '60%'}}
                                                    icon={<PlusOutlined/>}>
                                                Thêm nhân viên dự phòng
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>

                        <div className="div-form">
                            <label className="lb-form">Thông tin thanh toán</label>
                            <Form.List name={["contact", "payment"]}>
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, fieldKey, ...restField}) => (
                                            <Space key={key} style={{display: 'flex', marginBottom: 8}}
                                                   align="baseline">
                                                <Form.Item name={[name, 'bankName']} fieldKey={[fieldKey, 'bankName']}>
                                                    <Input placeholder="Tên ngân hàng"/>
                                                </Form.Item>
                                                <Form.Item name={[name, 'cardNumber']}
                                                           fieldKey={[fieldKey, 'cardNumber']}>
                                                    <Input placeholder="Số tài khoản"/>
                                                </Form.Item>
                                                <Form.Item name={[name, 'accountName']}
                                                           fieldKey={[fieldKey, 'accountName']}>
                                                    <Input placeholder="Tên tài khoản"/>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} style={{width: '60%'}}
                                                    icon={<PlusOutlined/>}>
                                                Thêm nhân viên dự phòng
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>

                    </Panel>

                    <Panel>
                        <PanelTitle>Hình ảnh hiển thị</PanelTitle>
                        <div>
                            <label>Hình ảnh chính</label>
                            <Form.Item label="Hình ảnh chính" name={['images']} noStyle>
                                <UploadImages/>
                            </Form.Item>
                        </div>
                    </Panel>
                    <Panel>
                        <PanelTitle>Đặc điểm khach sạn</PanelTitle>
                        <div>
                            <Form.Item name={['features']}>
                                <Checkbox.Group>
                                    {
                                        features.map((item: any, index: number) => {
                                            return <Checkbox key={item._id} value={item._id}
                                                             style={{lineHeight: '32px'}}>{item.name}</Checkbox>
                                        })
                                    }
                                </Checkbox.Group>
                            </Form.Item>
                        </div>
                    </Panel>

                    <Panel key={3}>
                        <PanelTitle>Tiện nghi</PanelTitle>
                        <div>
                            <Form.Item name={['amenities']}>
                                <Checkbox.Group>
                                    {
                                        amenityCategories.map((amenityCategory: any, indexAmenityCategory: number) => {
                                            const values = amenities.filter((x: any) => x.amenityCategoryId === amenityCategory._id);
                                            return values.length > 0 ? (
                                                <div key={indexAmenityCategory.toString()} className={'amenities'}>
                                                    <h5 className={'amenity-title'}>{amenityCategory.name}</h5>
                                                    <div>
                                                        {
                                                            values.map((item: any, index: number) => {
                                                                return <Checkbox key={item._id} value={item._id}
                                                                                 style={{lineHeight: '32px'}}>{item.name}</Checkbox>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            ) : null
                                        })
                                    }


                                </Checkbox.Group>
                            </Form.Item>
                        </div>
                    </Panel>
                </Content>

                <Content>
                    <div className={'form-actions'}>
                        <Button size={"large"} htmlType={"submit"} type="primary">Lưu lại</Button>
                        <Button size={"large"} onClick={event => history.goBack()}>Hủy bỏ</Button>
                    </div>
                </Content>
            </Form>
        </>
    )
}
